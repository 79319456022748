import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Grid } from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import FormInput from "components/Form/Controls/input";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  getGetHealthData,
  getDiseasesByProductId,
  getDoctorList,
  postSaveHealthData,
  postFormulaFile,
} from "api/apiList";
import {
  setFormFormulaDone,
  setFormulaMedicineQuantity,
  callReloadConsumptionTracing,
} from "actions/Impact";
import { setLoader } from "actions/Base";

import {
  PharmaceuticalUnitList,
  SupplyFrequencyList,
  DurationTreatmentList,
} from "constants/Enums";
import CardFileUpload from "components/Form/CardFileUpload";

const HealthDataForm = ({
  openDlg,
  handleRequestClose,
  selectedPrescription,
}) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));

  const [formulaData, setFormulaData] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [visitTargetIdList, setVisitTargetIdList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [dosageUnitList, setDosageUnitList] = useState([]);
  const [loadForm, setLoadForm] = useState({
    visitTargetIdListLoaded: false,
    diseaseListLoaded: false,
  });
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState("");

  const supplyFrequencyList = SupplyFrequencyList();

  const msgSuccess = IntlGetText("core.SuccessSave");

  const defaultValues = {
    companyId: null,
    visitTargetId: null,
    diseaseId: null,
    productId: null,
    medicineAmount: "",
    additionalGuidelines: "",
    pharmaceuticalQuantity: "",
    pharmaceuticalUnit: "",
    durationTreatment: "",
    dosageUnitId: "",
    quantity: "",
    supplyFrequency: "",
  };

  const validationSchema = {
    expeditionDate: yup
      .date()
      .nullable(true)
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
    companyId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    visitTargetId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    diseaseId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    productId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    durationTreatment: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const productIdValue = watch("productId");
  const medicineAmountValue = watch("medicineAmount");

  const getDInfo = (formulaData, prop) => {
    if (!formulaData) return "";

    return formulaData.dosage[prop] ? formulaData.dosage[prop] : "";
  };

  const loadData = () => {
    callApi(
      () => getGetHealthData(selectedPrescription.impactId),
      (data) => {
        setFormulaData(data);
        setFile(data.file);
        setCompanyList(data.companyList);
        setProductList(data.productList);
        const dosageUList = !data.dosageUnitList ? [] : data.dosageUnitList;
        setDosageUnitList(dosageUList);

        setValue(
          "medicineAmount",
          !data.medicineAmount ? "" : data.medicineAmount
        );
        setValue(
          "additionalGuidelines",
          !data.additionalGuidelines ? "" : data.additionalGuidelines
        );
        setValue("companyId", getCboValue(data.companyList, data.companyId));
        setValue("productId", getCboValue(data.productList, data.productId));
        setValue("code", data.code);
        setValue(
          "expeditionDate",
          !data || !data.expeditionDate ? null : dayjs(data.expeditionDate)
        );
        setValue(
          "durationTreatment",
          getCboValue(DurationTreatmentList(), data.durationTreatment)
        );
        //Dosage
        setValue(
          "dosageUnitId",
          getCboValue(dosageUList, getDInfo(data, "dosageUnitId"))
        );
        setValue("quantity", getDInfo(data, "quantity"));
        setValue(
          "supplyFrequency",
          getCboValue(supplyFrequencyList, getDInfo(data, "supplyFrequency"))
        );
      },
      fnSetLoader
    );
    callApi(getDoctorList, setVisitTargetIdList);
  };

  useEffect(() => {
    if (openDlg) loadData();
  }, [openDlg]);

  useEffect(() => {
    setValue("diseaseId", null);
    if (!productIdValue || !productIdValue.value) {
      setDiseaseList([]);
      return;
    }
    callApi(() => getDiseasesByProductId(productIdValue.value), setDiseaseList);

    var product = productList.find((x) => x.id == productIdValue.value);
    setValue(
      "pharmaceuticalUnit",
      getCboValue(PharmaceuticalUnitList(), product.pharmaceuticalUnit)
    );

    setValue(
      "dosageUnitId",
      getCboValue(formulaData.dosageUnitList, product.dosageUnitId)
    );

    updatePharmaceuticalQuantity();
  }, [productIdValue]);

  useEffect(() => {
    if (
      formulaData &&
      !loadForm.visitTargetIdListLoaded &&
      visitTargetIdList.length > 0
    ) {
      setValue(
        "visitTargetId",
        getCboValue(visitTargetIdList, formulaData.visitTargetId)
      );
      setLoadForm({ ...loadForm, visitTargetIdListLoaded: true });
    }
  }, [formulaData, visitTargetIdList]);

  useEffect(() => {
    if (formulaData && !loadForm.diseaseListLoaded && diseaseList.length > 0) {
      setValue("diseaseId", getCboValue(diseaseList, formulaData.diseaseId));
      setLoadForm({ ...loadForm, diseaseListLoaded: true });
    }
  }, [diseaseList]);

  const updatePharmaceuticalQuantity = () => {
    if (!productIdValue || !productIdValue.value || !medicineAmountValue) {
      setValue("pharmaceuticalQuantity", 0);
      return;
    }

    const product = productList.find((x) => x.id == productIdValue.value);

    setValue(
      "pharmaceuticalQuantity",
      product.pharmaceuticalQuantity * medicineAmountValue
    );
  };

  useEffect(() => updatePharmaceuticalQuantity(), [medicineAmountValue]);

  const onSubmit = async (d, e) => {
    const obj = {
      id: formulaData.id,
      impactId: formulaData.impactId,
      productId: Number(d.productId.value),
      diseaseId: Number(d.diseaseId.value),
      visitTargetId: Number(d.visitTargetId.value),
      companyId: Number(d.companyId.value),
      durationTreatment: Number(d.durationTreatment.value),
      medicineAmount: !d.medicineAmount ? null : Number(d.medicineAmount),
      additionalGuidelines: d.additionalGuidelines,
      code: d.code,
      expeditionDate:
        d.expeditionDate != null
          ? dayjs(d.expeditionDate).format("YYYY-MM-DDTHH:mm:ss")
          : null,

      dosageId: Number(getDInfo(formulaData, "id")),
      dosageQuantity: Number(d.quantity),
      supplyFrequency:
        d.supplyFrequency && d.supplyFrequency.value
          ? Number(d.supplyFrequency.value)
          : null,
    };
    await callApiAsync(
      () => postSaveHealthData(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        dispatch(setFormFormulaDone(data.expeditionDate));
        dispatch(setFormulaMedicineQuantity(data.medicineQuantity));
        dispatch(callReloadConsumptionTracing(true));
        handleRequestClose();
      },
      fnSetLoader
    );
  };

  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <FormProvider {...methods}>
        <form className="row" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="code"
                label="Nro. Prescripción"
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormDatePicker
                name="expeditionDate"
                label="Fecha de Expedición"
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="companyId"
                label={<IntlMessages id="core.institution" />}
                options={companyList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="visitTargetId"
                label={<IntlMessages id="core.doctor" />}
                options={visitTargetIdList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="productId"
                label={<IntlMessages id="formula.product" />}
                options={productList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="medicineAmount"
                label={<IntlMessages id="formula.medicineAmount" />}
                margin="normal"
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="pharmaceuticalQuantity"
                label={<IntlMessages id="formula.pharmaceuticalQuantity" />}
                margin="normal"
                disabled={true}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="pharmaceuticalUnit"
                label={<IntlMessages id="formula.pharmaceuticalUnit" />}
                options={PharmaceuticalUnitList()}
                value={watch("pharmaceuticalUnit")}
                onChange={cboHandleChange("pharmaceuticalUnit")}
                margin="normal"
                disabled={true}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                name="quantity"
                label={<IntlMessages id="core.dosage" />}
                margin="normal"
                disabled={false}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="dosageUnitId"
                label={<IntlMessages id="formula.dosageUnit" />}
                options={dosageUnitList}
                margin="normal"
                disabled={true}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="supplyFrequency"
                label={<IntlMessages id="formula.supplyFrequency" />}
                options={supplyFrequencyList}
                margin="normal"
                disabled={false}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                name="durationTreatment"
                label={<IntlMessages id="formula.durationTreatment" />}
                options={DurationTreatmentList()}
                value={watch("durationTreatment")}
                margin="normal"
                disabled={false}
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormSelectAutoComplete
                name="diseaseId"
                label={<IntlMessages id="formula.diagnostic" />}
                options={diseaseList}
                margin="normal"
                required={true}
                errorobj={errors}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                name="additionalGuidelines"
                label={<IntlMessages id="formula.additionalGuidelines" />}
                margin="normal"
                multiline
                rows={3}
                errorobj={errors}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
        <ButtonAsync
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {<IntlMessages id="core.save" />}
        </ButtonAsync>
        {(!disabled || file) && formulaData && (
          <CardFileUpload
            disabled={false}
            file={file}
            setFile={setFile}
            actionApi={postFormulaFile}
            actionApiInfo={{ impactId: formulaData.impactId }}
          />
        )}
      </div>
    </div>
  );
};

export default HealthDataForm;
