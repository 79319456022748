import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollContent from "../scrollContent";
import LabelsSection from "../labelsSection";
import Resources from "./components/resources"
import Feedback from "./components/feedback"
import {
  callApi,
  getEventTrainingDetail
} from "api/apiList";
import { setLoader } from "actions/Base";

const EventTrainingDetail = ({ prescription }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { width } = useSelector(({ settings }) => settings);

  const [ eventTrainingInfo, setEventTrainingInfo] = useState(null);

  const refreshEventTrainingInfo = () => {
    callApi(
      ()=>getEventTrainingDetail(prescription.eventTraining.id), 
      setEventTrainingInfo,
      fnSetLoader)
  }

  useEffect(()=>{
    if(!prescription)
      return;

    refreshEventTrainingInfo();
  },[prescription])

  return (
    <ScrollContent
      width={width}
      classParent="module-detail mail-detail"
      heightMax="calc(100vh - 309px)"
      heightMin="calc(100vh - 288px)"
    >
      <div className="mail-header">
        <div className="mail-header-content col pl-0">
          <div className="subject">
            <strong>{prescription.eventTraining.name.toUpperCase()}</strong>
          </div>
          <LabelsSection prescription={prescription}/>
        </div>
      </div>
      <hr />
      <div>
        <div className="row first-impact-box-margins">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <Resources eventTrainingInfo={eventTrainingInfo}/>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <Feedback eventTrainingInfo={eventTrainingInfo} refreshEventTrainingInfo = {refreshEventTrainingInfo}/>
          </div>
        </div>
      </div>
    </ScrollContent>
  );
};

export default EventTrainingDetail;

