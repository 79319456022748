import React, { useState } from "react";
import { useSelector } from "react-redux";
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import IntlMessages from "util/IntlMessages";
import HealthDataForm from "./form";

const HealthData = () => {
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { expeditionDateFormula } = useSelector(
    ({ impact }) => impact
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleRequestClose = () => setOpen(false);
  
  return (
    <div>
      <InfoCard
        data={{
          icon: "file-text",
          title: "Formula",
          subTitle: "Médica",
          color: expeditionDateFormula == null ? "#E53935" : "#20c997"
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog open={open} maxWidth="md" 
        disableBackdropClick={true} onClose={handleRequestClose}>
        <DialogTitle>
          <IntlMessages id="core.medicalFormula" />
        </DialogTitle>
        <DialogContent>
          <HealthDataForm
            openDlg={open}
            handleRequestClose={handleRequestClose}
            selectedPrescription={selectedPrescription}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HealthData;