import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import FormRemodulin from "./FormRemodulin";
import Table from "./Table";
import Indicator from "./Indicator";
import {
  callApi,
  callApiAsync,
  getMedicineConsumptionList,
  postCreateUpdateMedicineConsumption,
  deleteMedicineConsumption,
  getMedicineList,
  getGetIndicators,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import { ProductBehavior } from "constants/Enums";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const MedicineConsumption = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired } = useSelector(({ impact }) => impact);

  const msgConsentRequired = IntlGetText("impact.consentRequired");
  const msgInvalidAddOperation = IntlGetText(
    "medicineDelivery.invalidAddOperation"
  );

  const [formLoaded, setFormLoaded] = useState(false);

  const [consumptionList, setConsumptionList] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [indicatorInfo, setIndicatorInfo] = useState(null);
  const [currentRowObj, setCurrentRowObj] = useState(null);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentPath) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };

  const loadData = () => {
    callApi(
      () => getMedicineConsumptionList(selectedPrescription.id),
      setConsumptionList
    );
    callApi(() => getGetIndicators(selectedPrescription.id), setIndicatorInfo);
  };

  useEffect(() => loadData(), []);
  
  useEffect(() => {
    if(open){
      loadData();
      if(!formLoaded){
        callApi(
          () => getMedicineList(selectedPrescription.impactId),
          setMedicineList
        );
        setFormLoaded(true);
      }
    }
  }, [open]);

  const existMedicineInImpact = (medicineConsumption) => {
    return (
      consumptionList.find(
        (x) =>
          x.impactId == selectedPrescription.impactId &&
          x.medicineDeliveryDetailId ===
            medicineConsumption.medicineDeliveryDetailId &&
          x.id !== medicineConsumption.id
      ) !== undefined
    );
  };

  const handleCreateUpdate = async (data) => {
    if (existMedicineInImpact(data)) {
      NotificationManager.warning(msgInvalidAddOperation);
      return;
    }
    await callApiAsync(
      () => postCreateUpdateMedicineConsumption(data),
      () => {
        handleRequestCloseCRU();
        loadData();
      }
    );
  };

  const handleDelete = (data) => {
    callApi(
      () => deleteMedicineConsumption(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;

  const isRemodulin = (selectedPrescription.customProductBehaviorId === ProductBehavior.remodulin || selectedPrescription.customProductBehaviorId === ProductBehavior.dacepton);
  return (
    <div>
      <InfoCard
        data={{
          icon: "hospital",
          title: IntlGetText("medicineConsumption.cardTitle"),
          subTitle: IntlGetText("medicineConsumption.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            consumptionList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="medicineDelivery.dlgTitle" />
        </DialogTitle>
        <DialogContent>
          <Indicator 
            indicatorInfo={indicatorInfo}
            isRemodulin = {isRemodulin}/>
          <Table
            data={consumptionList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            isRemodulin = {isRemodulin}
            readOnly={readOnly}
          />
          { !isRemodulin &&
            <Form
              obj={currentRowObj}
              impactId={selectedPrescription.impactId}
              openCRU={openCRU}
              handleRequestClose={handleRequestCloseCRU}
              handleCreateUpdate={handleCreateUpdate}
              medicineList={medicineList}
              readOnly={readOnly}
            />
          }
          { isRemodulin &&
            <FormRemodulin
              obj={currentRowObj}
              impactId={selectedPrescription.impactId}
              openCRU={openCRU}
              handleRequestClose={handleRequestCloseCRU}
              handleCreateUpdate={handleCreateUpdate}
              medicineList={medicineList}
              readOnly={readOnly}
            />
          }
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MedicineConsumption;
