import {
    SET_CONSENT_PATH,
    LOAD_INFO_IMPACT,
    SET_IMPACT_START_DATE,
    SET_CLINICAL_HISTORY_STATE,
    SET_FORM_FORMULA_DONE,
    SET_FORMULA_MEDICINE_QUANTITY,
    ON_START_IMPACT,
    CALL_RELOAD_ALERTS,
    CALL_RELOAD_PATIENT_DATA,
    CALL_RELOAD_CONSUMPTION_TRACING,
    SET_HISTORY_DATA  /* ================ */
  } from "constants/ActionTypes";
  
const INIT_STATE = {
    consentPath: null,
    patientFullName: null,
    referralConsentFile: null,
    impactStartDate: null,
    impactEndDate: null,
    expeditionDateFormula: null,
    clinicalHistoryStateDone: false,
    startImpact:false,
    //file clinic history variableGroupId
    fileCHVariableGroupId: 0,
    reloadAlerts:false,
    reloadPatientData:false,
    reloadConsumptionTracing:false,
    isCIRequired: false,
    ciDisplayable: null,
    historyData : false,  /* ================ */
    medicineQuantity: null
  };
 
export default (state = INIT_STATE, {type, payload}) => {
    switch (type) {
        case SET_CONSENT_PATH: {
            return { ...state, 
                consentPath: payload
            };
        }
        case LOAD_INFO_IMPACT: {
            return { ...state, ...payload };
        }
        case SET_IMPACT_START_DATE: {
            return { ...state, 
                impactStartDate: payload,
            };
        }
        case SET_CLINICAL_HISTORY_STATE: {
            return { ...state, clinicalHistoryStateDone: payload};
        }
        case SET_HISTORY_DATA: {  /* ================ */
            return { ...state, historyData: payload};
        }
        case SET_FORM_FORMULA_DONE: {
            return { ...state, expeditionDateFormula: payload};
        }
        case SET_FORMULA_MEDICINE_QUANTITY: {
            return { ...state, medicineQuantity: payload};
        }
        case ON_START_IMPACT: {
            return { ...state, startImpact: payload};
        }
        case CALL_RELOAD_ALERTS: {
            return { ...state, reloadAlerts: payload};
        }
        case CALL_RELOAD_PATIENT_DATA: {
            return { ...state, reloadPatientData: payload};
        }
        case CALL_RELOAD_CONSUMPTION_TRACING: {
            return { ...state, reloadConsumptionTracing: payload};
        }
        
        default:
        return state;
    }
};
  