import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { StyledFormControl, StyledAutoSelectInputLabel } from "../styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import "./index.css";

const stylesReactSelect = {
  clearIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    cursor: "pointer",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    color: state.selectProps.error ? "#f44336" : "rgba(0, 0, 0, 0.54)",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: 0,
    borderBottom: state.selectProps.error
      ? "1px solid #f44336"
      : "1px solid rgba(0,0,0,0.87)",
    boxShadow: "none",
    ":hover": {
      borderColor: state.selectProps.error ? "1px solid #f44336" : "inherit",
      boxShadow: state.selectProps.error ? "1px solid #f44336" : "none",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: 0,
  }),
};

const components = {
  Option,
};

function Option(props) {
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;
  return (
    <div {...newInnerProps} className="autoselect-options">
      {props.children}
    </div>
  );
}

export const ReactSelect = (props) => {
  const { label, options, required, errorobj, value, isClearable, name } = props;

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {    
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return (
    <React.Fragment>
      <StyledFormControl>
        <StyledAutoSelectInputLabel>
          <span className={isError ? "req-label" : ""}>
            {label} {required ? <span className="req-label">*</span> : null}
          </span>
        </StyledAutoSelectInputLabel>
        <Select
          options={options}
          placeholder="Please Select"
          valueKey="id"
          components={components}
          isClearable={ isClearable === undefined ? true : isClearable}
          styles={stylesReactSelect}
          isSearchable={true}
          filterOption={createFilter({ ignoreAccents: false })}
          error={isError}
          value={value}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 })
          }}
          menuPortalTarget={document.body}
          {...props}        
        />
        {isError && (
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        )}
      </StyledFormControl>
    </React.Fragment>
  );
};

function FormSelectAutoComplete(props) {
  const { control } = useFormContext();
  const { name, defaultValue, value, options, disabled, onChange, ...selectProps } = props;

  const [newData, setNewData] = useState([]);
  
  useEffect(() => {
    const newOptions = options.map((data, index) => ({
      label: data.name,
      value: data.id,
    }));
    setNewData(newOptions);
  }, [options]);

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={name}       
        defaultValue={defaultValue}      
        value={value}  
        render={({ onBlur, onChange, value }) => (
          <ReactSelect
            options={newData}
            name={name}
            onBlur={onBlur}
            onChange={(value)=>{
              onChange(value);
              if(props.onChange)
                props.onChange(value);
            }}      
            value={value} 
            isDisabled={disabled}      
            {...selectProps}
          />
        )}
        
      />
    </React.Fragment>
  );
}

export const getCboValue = (items, id) => {
  if (typeof id == "object") {
    return id;
  }
  const result = items.find((x) => x.id === id);
  return result ? { label: result.name, value: result.id } : "";
};

export const getCboValues = (items, idList) => {  
  if(!idList)
    return [];
    
  var response = idList.map(id=>{
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : undefined;
  })

  return response.filter(x=> x !== undefined);
};

export default FormSelectAutoComplete;
