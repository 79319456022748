import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton 
} from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages from "util/IntlMessages";
import FormSelectAutoComplete from "../../../../../components/Form/Controls/select-autocomplete";
import FormInput from "../../../../../components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  getInsuranceList,
  getInstitutionList,
  getDoctorList,
  getProductList,
  postNewPatientAlert,
  getPrescriptions
} from "../../../../../api/apiList";
import {  setSelectedPrescription, loadPrescriptions, showErrorMessage } from "actions/Management";
import { setLoader } from "actions/Base";
import { getUserInfo } from "app/routes/psp/services/userService";
import { ShardKeyEnum } from "constants/Enums";

const validationSchema = yup.object().shape({
  cboInsurance: yup
    .object()
    .shape({value:yup.string().required()})
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable(),
  cboInstitution: yup
    .object()
    .shape({value:yup.string().required()})
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable(),
  cboDoctor: yup
    .object()
    .shape({value:yup.string().required()})
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable(),
  cboProduct: yup
    .object()
    .shape({value:yup.string().required()})
    .required(<IntlMessages id="core.fieldRequired" />)
    .nullable(),
});

const defaultValues = {
  cboInsurance:"",
  cboInstitution:"",
  cboDoctor:"",
  cboProduct:"",
  comment:"",
};

const DialogContainer = ({children, ...props}) => {
  const {open, handleClickOpen, handleRequestClose, dialogTitle } = props;
  return (
    <div>
     <IconButton
       variant="contained"
       onClick={handleClickOpen}
       className="icon-btn text-white"
     >
       <i className={`zmdi zmdi-account-add zmdi-hc-fw`} />
     </IconButton>
     <Dialog open={open} disableBackdropClick={true} onClose={handleRequestClose}>
       <DialogTitle>{dialogTitle}</DialogTitle>
       {children}
      </Dialog>
    </div>
  );
}

const NewPatient = () =>{
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);

  const handleRequestClose = () => {
    reset(defaultValues);
    setOpen(false);
  }

  const methods = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const { reset } = methods;

  return (
    <React.Fragment>                     
      <DialogContainer 
        open={open} 
        handleClickOpen={handleClickOpen} 
        handleRequestClose={handleRequestClose} 
        dialogTitle={<IntlMessages id="alert.newPatient" />}> 
        <NewPatientTest5 handleRequestClose={handleRequestClose} methods={{...methods}}/>        
      </DialogContainer>
    </React.Fragment>
  );
}

const NewPatientTest5 = ({handleRequestClose, methods}) => {  
  let history = useHistory();
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  
  const { handleSubmit, errors, setValue, watch } = methods;  

  const [insurances, setInsurance] = useState([]);
  const [institutions, setInstitution] = useState([]);
  const [doctors, setDoctor] = useState([]);
  const [products, setProduct] = useState([]);

  const insuranceValue = watch("cboInsurance");
  const institutionValue = watch("cboInstitution");
  const doctorValue = watch("cboDoctor");
  const productValue = watch("cboProduct");  

  useEffect(() => {
    callApi(getInsuranceList, setInsurance, fnSetLoader);
    callApi(getProductList, setProduct, fnSetLoader);    
    callApi(getInstitutionList, setInstitution, fnSetLoader);
    callApi(getDoctorList, setDoctor, fnSetLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const cboHandleChange = (name) => (event) => {
    setValue(name, event);
  };

  const callGetPrescriptions = () => {
    callApi(
      ()=> getPrescriptions(0),
      (data)=>{
        dispatch(loadPrescriptions(data, 0));
        history.push('/app/management');
      },   
      fnSetLoader
    );
  };

  const prescriptionSaveSuccess = (data) => {
    handleRequestClose();
    if(getUserInfo().shardKey === ShardKeyEnum.ialuril){
      //history.push('/app/patientPA');
      history.push({
        pathname: '/app/patientPA',
        state: { reloadPage: new Date() }
      });
      return;
    }

    dispatch(setSelectedPrescription(data));
    callGetPrescriptions();
  };

  const onSubmit = async (data, e) => {
    var obj ={
      insuranceId: parseFloat(data.cboInsurance.value),
      institutionId: parseFloat(data.cboInstitution.value),
      doctorId: parseFloat(data.cboDoctor.value),
      productId: parseFloat(data.cboProduct.value),
      comment: data.comment,
    };

    await callApiAsync(() => postNewPatientAlert(obj), prescriptionSaveSuccess, fnSetLoader);
  };

  return (   
    <div>
      <DialogContent className="new-patient-dialog-content">
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                label={<IntlMessages id="core.insurance" />}
                options={insurances}
                required={true}
                name="cboInsurance"
                value={insuranceValue}                           
                onChange={cboHandleChange("cboInsurance")}
                errorobj={errors}                                    
              />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                label={<IntlMessages id="core.institution" />}
                options={institutions}
                required={true}
                name="cboInstitution"
                value={institutionValue}                           
                onChange={cboHandleChange("cboInstitution")}
                errorobj={errors}                                    
              />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormSelectAutoComplete
                  label={<IntlMessages id="core.doctor" />}
                  options={doctors}
                  required={true}
                  name="cboDoctor"
                  value={doctorValue}                           
                  onChange={cboHandleChange("cboDoctor")}
                  errorobj={errors}                                    
                />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
              <FormSelectAutoComplete
                  label={<IntlMessages id="core.product" />}
                  options={products}
                  required={true}
                  name="cboProduct"
                  value={productValue}                           
                  onChange={cboHandleChange("cboProduct")}
                  errorobj={errors}                                    
                />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="core.comment" />}
                  name="comment"
                  multiline
                  rows={5}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  errorobj={errors}
                />
                </Grid>
              </Grid>
          </form>
        </FormProvider>
       </DialogContent>
       <DialogActions>
         <Button onClick={handleRequestClose} color="secondary">
         {<IntlMessages id="core.cancel" />}
         </Button>
         <ButtonAsync onClick={handleSubmit(onSubmit)} color="primary">
            {<IntlMessages id="core.save" />}
          </ButtonAsync>
       </DialogActions>
  </div>       
  );
}

export default NewPatient;
