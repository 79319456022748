import React from "react";
import { RoleEnum, ShardKeyEnum } from "constants/Enums";
import { IntlGetText } from "util/IntlMessages";

const menuSuperAdmin = [
  {
    link: "/app/relogin",
    name: "core.Relogin",
    roles: [RoleEnum.superAdmin]
  }
];

const menuIaluril = [
  {
    link: "/app/patientPA",
    name: "psp.menu.patients",
    roles: [RoleEnum.nurse, RoleEnum.rep, RoleEnum.consultation]
  },
  {
    link: "/app/impactPlanning",
    name: "psp.menu.impactPlanning",
    roles: [RoleEnum.nurse, RoleEnum.consultation]
  },
  {
    link: "/app/patientPA/dashboard",
    name: "patientPA.dashboard",
    roles: [RoleEnum.nurse, RoleEnum.rep, RoleEnum.consultation]
  },
  {
    link: "/app/patientPA/clinicalDashboard",
    name: "patientPA.dashboardClinial",
    roles: [RoleEnum.nurse, RoleEnum.consultation, RoleEnum.kpi_survey]
  }
];

const genericMenu = [
  {
    link: "/app/prescriptions",
    name: "core.prescriptions",
    roles: [RoleEnum.callAgent, RoleEnum.callAgentManager]
  },
  {
    link: "/app/dashboard-migration",
    name: "core.dashboard",
    roles: [RoleEnum.callAgent, RoleEnum.callAgentManager]
  },
  {
    link: "/app/reportList",
    name: "menu.reports",
    roles: [RoleEnum.callAgent, RoleEnum.callAgentManager]
  },
];

const setupMenu = [
  {
    link: "/app/home",
    name: "psp.menu.home",
    roles: [RoleEnum.nurse]
  },
  {
    link: "/app/dashboardKPI",
    name: "core.dashboard",
    roles: [RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.nursemanagerconsultant]
  },
  {
    link: "/app/management",
    name: "psp.menu.manage",
    roles: [RoleEnum.nurse, RoleEnum.nurse_procedure]
  },
  {
    link: "/app/impactPlanning",
    name: "psp.menu.impactPlanning",
    roles: [RoleEnum.nurse, RoleEnum.nurse_procedure, RoleEnum.psp_manager, RoleEnum.psp_coordinator]
  },
  {
    link: "/app/patient",
    name: "psp.menu.patients",
    roles: [RoleEnum.nurse, RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.msl, RoleEnum.consultation, RoleEnum.nursemanagerconsultant]
  },
  {
    link: "/app/reports",
    name: "menu.reports",
    roles: [RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.msl, RoleEnum.patient_assignment]
  },
  {
    link: "/app/reportsRepKam",
    name: "menu.reports",
    roles: [RoleEnum.visitor, RoleEnum.kam]
  },
  {
    link: "/app/patient/management",
    name: "menu.patientManagement",
    roles: [RoleEnum.psp_manager, RoleEnum.psp_coordinator, RoleEnum.patient_assignment]
  },
  {
    link: "/app/adverseEvent",
    name: "psp.menu.adverseEvents",
    roles: [RoleEnum.pharmacovigilance]
  },
  {
    link: "/app/institutionHome",
    name: "core.institutions",
    roles: [RoleEnum.institutionManager]
  }
];

const institutionMenu = [
  {
    link: "/app/institutionHome",
    name: "psp.menu.home",
    roles: [RoleEnum.institutionManager, RoleEnum.institution]
  }
];
  
const getMenuByRole = (role, shardKey) =>{
  if(shardKey === ShardKeyEnum.ialuril)
    return menuIaluril.filter(x=>x.roles.indexOf(role) !== -1);

  if(role === RoleEnum.institution)
    return institutionMenu.filter(x=>x.roles.indexOf(role) !== -1);  

  if(shardKey === ShardKeyEnum.pintPharma || 
    shardKey === ShardKeyEnum.ullkupuy || 
    shardKey === ShardKeyEnum.boidor || 
    shardKey === ShardKeyEnum.cmedi || 
    shardKey === ShardKeyEnum.phg || 
    shardKey === ShardKeyEnum.jaasielvalentech || 
    shardKey === ShardKeyEnum.sanando || 
    shardKey === ShardKeyEnum.jaasielmegalabs || 
    shardKey === ShardKeyEnum.jaasielcipla ||
    shardKey === ShardKeyEnum.jaasielsophia ||
    shardKey === ShardKeyEnum.jaasielprocaps ||
    shardKey === ShardKeyEnum.jaasielgt ||
    shardKey === ShardKeyEnum.jaasielhb ||
    shardKey === ShardKeyEnum.jaasielaltadis||
    shardKey === ShardKeyEnum.akompaname||
    shardKey === ShardKeyEnum.jaasielServier ||
    shardKey === ShardKeyEnum.atulado ||
    shardKey === ShardKeyEnum.operando ||
    shardKey === ShardKeyEnum.celltrion
    )
    return setupMenu.filter(x=>x.roles.indexOf(role) !== -1);

  if(role === RoleEnum.superAdmin)
    return menuSuperAdmin.filter(x=>x.roles.indexOf(role) !== -1);
    
  return genericMenu.filter(x=>x.roles.indexOf(role) !== -1);
}

const isRole = (userInfo, role) => userInfo.roles.indexOf(role) !== -1;

export const getMenuItems = (userInfo) => {
  let menu = [];
  if(!userInfo)
    return menu;
  
  userInfo.roles.map(x=>{
    menu = menu.concat(getMenuByRole(x,userInfo.shardKey));
  });

  const uniqueLinks = menu.map(item => item.link)
    .filter((value, index, self) => self.indexOf(value) === index);

  const result = uniqueLinks.map(x=>menu.find(y=>y.link===x));
  return result;
}

export const getInitURL =(userInfo)=>{
  if(isRole(userInfo, RoleEnum.nurse_procedure))
    return "/app/management";

  if(userInfo.shardKey === ShardKeyEnum.ialuril){
    if(isRole(userInfo, RoleEnum.kpi_survey))
      return "/app/patientPA/clinicalDashboard";
    return "/app/patientPA";
  }
  else if(isRole(userInfo, RoleEnum.superAdmin))
    return "/app/relogin";
    else if(isRole(userInfo, RoleEnum.nursemanagerconsultant))
    return "/app/dashboardKPI";
  else if(isRole(userInfo, RoleEnum.pharmacovigilance))
    return "/app/adverseEvent";
  else if(isRole(userInfo, RoleEnum.psp_manager) || isRole(userInfo, RoleEnum.psp_coordinator) || isRole(userInfo, RoleEnum.msl))
    return "/app/patient";
  else if(isRole(userInfo, RoleEnum.callAgent) || isRole(userInfo, RoleEnum.callAgentManager))
    return "/app/prescriptions";
  else if(isRole(userInfo, RoleEnum.visitor) || isRole(userInfo, RoleEnum.kam))
    return "/app/reportsRepKam";
  else if(isRole(userInfo, RoleEnum.institution) || isRole(userInfo, RoleEnum.institutionManager))
    return "/app/institutionHome";
  return "/app/home";
}

export const TestMenu = () => {
  let menu = getMenuByRole(RoleEnum.nurse);
  console.log("***********---Nurse Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });
  
  menu = getMenuByRole(RoleEnum.nurse, ShardKeyEnum.ialuril);
  console.log("***********---Nurse ialuril Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });
  
  menu = getMenuByRole(RoleEnum.psp_manager);
  console.log("***********---psp_manager Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });

  
  menu = getMenuByRole(RoleEnum.pharmacovigilance);
  console.log("***********---pharmacovigilance Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });
  
  menu = getMenuByRole(RoleEnum.visitor);
  console.log("***********---visitor Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });

  
  menu = getMenuByRole(RoleEnum.kam);
  console.log("***********---KAM Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });

  menu = getMenuByRole(RoleEnum.msl, ShardKeyEnum.pintPharma);
  console.log("***********---MSL Menu---***********");
  menu.map(x=>{
    console.log(IntlGetText(x.name)+" - "+x.link)
  });
  return (<></>);
}




