export default [
  {
    id: 0,
    handle: "inbox",
    title: "Nuevos Pacientes",
    icon: "accounts-alt",
  },
  {
    id: 1,
    handle: "sent",
    title: "En Trámite",
    icon: "hourglass-alt",
  },
  {
    id: 2,
    handle: "drafts",
    title: "Seguimiento",
    icon: "folder",
  },
  {
    id: 3,
    handle: "spam",
    title: "Eventos Adversos",
    icon: "alert-octagon",
  },
  {
    id: 4,
    handle: "trash",
    title: "Capacitación / Eventos",
    icon: "book",
  },
];
