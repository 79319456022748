import { IntlGetText } from "util/IntlMessages";

export const genderList = () => [
  { id: 0, name: IntlGetText("gender.man") },
  { id: 1, name: IntlGetText("gender.woman") },
];
export const educationalLevelList = () => [
  { id: 0, name: "Ninguna" },
  { id: 1, name: "Primaria" },
  { id: 2, name: "Secundaria" },
  { id: 3, name: "Técnico" },
  { id: 4, name: "Universitario" },
];
export const CivilStatusList = () => [
  { id: 2, name: IntlGetText("civilStatus.notMarriedGreaterTwoYears") },
  { id: 3, name: IntlGetText("civilStatus.notMarriedLessTwoYears") },
  { id: 4, name: IntlGetText("civilStatus.separatedDivorced") },
  { id: 5, name: IntlGetText("civilStatus.widower") },
  { id: 0, name: IntlGetText("civilStatus.single") },
  { id: 1, name: IntlGetText("civilStatus.married") },
];
export const RelationshipList = () => [
  { id: 0, name: "Pareja" },
  { id: 1, name: "Padre" },
  { id: 2, name: "Hijo" },
  { id: 3, name: "Hermano" },
  { id: 4, name: "Otro" },
];
export const HousingTypeList = () => [
  { id: 0, name: IntlGetText("housingType.urban") },
  { id: 1, name: IntlGetText("housingType.rural") },
  { id: 2, name: IntlGetText("housingType.toleranceZone") },
  { id: 3, name: IntlGetText("housingType.scatteredZone") },
];
export const SupportNetList = () => [
  { id: 0, name: IntlGetText("supportNet.goob") },
  { id: 1, name: IntlGetText("supportNet.regular") },
  { id: 2, name: IntlGetText("supportNet.bad") },
];
export const RegimeList = () => [
  { id: 0, name: IntlGetText("regime.notApply") },
  { id: 1, name: IntlGetText("regime.undefined") },
  { id: 2, name: IntlGetText("regime.contributive") },
  { id: 3, name: IntlGetText("regime.EASSpecial") },
  { id: 4, name: IntlGetText("regime.exception") },
  { id: 5, name: IntlGetText("regime.prepaid") },
  { id: 6, name: IntlGetText("regime.subsidized") }
];
export const AffiliationTypeList = () => [
  { id: 0, name: "Beneficiario" },
  { id: 1, name: "Cotizante" },
];
export const PrescriptionTypeList = () => [
  { id: 0, name: IntlGetText("core.successive") },
  { id: 1, name: IntlGetText("core.unique") },
];
export const DurationTreatmentList = () => [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" },
  { id: 8, name: "8" },
  { id: 9, name: "9" },
  { id: 10, name: "10" },
  { id: 11, name: "11" },
  { id: 12, name: "12" },
];
export const PharmaceuticalUnitList = () => [
  { id: 0, name: "Tabletas" },
  { id: 1, name: "Viales" },
  { id: 2, name: "Cápsulas" },
  { id: 3, name: "Kit" },
  { id: 4, name: "Membrana" },
  { id: 5, name: "Carpulas" }
];
export const FormulaTypeList = () => [
  { id: 0, name: "MIPRES" },
  { id: 1, name: "No MIPRES" },
];
export const AlertStateList = () => [
  { id: 0, name: IntlGetText("alertState.pending") },
  { id: 1, name: IntlGetText("alertState.done") },
  { id: 2, name: IntlGetText("alertState.inProgress") },
  { id: 3, name: IntlGetText("alertState.notResolved") },
];
export const AlertTypeList = () => [
  { id: 0, name: IntlGetText("alertType.newPatient") },
  { id: 1, name: IntlGetText("alertType.doctorDidNotAccept") },
  { id: 2, name: IntlGetText("alertType.patientDidNotAccept") },
  { id: 3, name: IntlGetText("alertType.formulatedWithErrors") },
  { id: 4, name: IntlGetText("alertType.radicacionEPSNonEffective") },
  { id: 5, name: IntlGetText("alertType.authorizationDelays") },
  { id: 6, name: IntlGetText("alertType.noAuthorized") },
  { id: 7, name: IntlGetText("alertType.authorizationWithInconsistencies") },
  { id: 8, name: IntlGetText("alertType.radicacionOLFNonEffective") },
  { id: 9, name: IntlGetText("alertType.incompleteDelivery") },
  { id: 10, name: IntlGetText("alertType.deliveryNotMade") },
  { id: 11, name: IntlGetText("alertType.cancelled") },
  { id: 12, name: IntlGetText("alertType.newFomurlationCycle") },
];
export const ImpactStateList = () => [
  { id: 0, name: IntlGetText("impactState.planning") },
  { id: 1, name: IntlGetText("impactState.registered") },
  { id: 2, name: IntlGetText("impactState.cancelled") },
];
export const SupplyFrequencyList = () => [
  { id: 8, name: "8" },
  { id: 12, name: "12" },
  { id: 24, name: "24" },
  { id: 48, name: "48" },
  { id: 72, name: "72" },
];
export const StratumList = () => [
  { id: 0, name: "0" },
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
];
export const PatientStateList = (isJaasModule, isFullList = false) => {
  if(isJaasModule)
  {
    var stateList= !isFullList ? []
    : [ { id: 0, parentId: 1, color: "first-impact", name: IntlGetText("patientState.pending") }];
    return [
      ...stateList,
      { id: 1, parentId: 1, color: "first-impact", name: IntlGetText("patientStateJaasModule.deliveryByEps") },
      { id: 2, parentId: 1, color: "first-impact", name: IntlGetText("patientStateJaasModule.particularPurchase") },
      { id: 3, parentId: 2, color: "first-impact", name: IntlGetText("patientStateJaasModule.pendingAuthorization") },
      { id: 4, parentId: 2, color: "first-impact", name: IntlGetText("patientStateJaasModule.pendingAppointment") },
      { id: 5, parentId: 2, color: "first-impact", name: IntlGetText("patientStateJaasModule.pendingDelivery") },
      { id: 6, parentId: 2, color: "first-impact", name: IntlGetText("patientStateJaasModule.notAnswer") },
      { id: 7, parentId: 3, color: "first-impact", name: IntlGetText("patientStateJaasModule.transactInEps") },
      { id: 8, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.lockAuthorization") },
      { id: 9, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.lockOlf") },
      { id: 10, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.lackMedicalAppointment") },
      { id: 11, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.wrongFormula") },
      { id: 12, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.wronguthorization") },
      { id: 13, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.differentDeliveryPharmacy") },
      { id: 14, parentId: 4, color: "first-impact", name: IntlGetText("patientStateJaasModule.lockNotAnswer") },
      { id: 15, parentId: 5, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.medicalSuspension") },
      { id: 16, parentId: 5, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.voluntarySuspension") },
      { id: 17, parentId: 6, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.finishedByDoctor") },
      { id: 18, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.outsideNotAnswer") },
      { id: 19, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.wrongPhone") },
      { id: 20, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.phoneOutService") },
      { id: 21, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.notAcceptPsp") },
      { id: 22, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.notAcceptTreatment") },
      { id: 23, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.death") },
      { id: 24, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.finishedTreatment") },
      { id: 25, parentId: 7, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.changeOfTreatment") },
      { id: 26, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.unformulated") },
      { id: 27, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.noEnrolledNotAnswer") },
      { id: 28, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.noEnrolledNotAcceptPsp") },
      { id: 29, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.noEnrolledWrongPhone") },
      { id: 30, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.noEnrolledPhoneOutService") },
      { id: 31, parentId: 8, color: "pat-state-1", name: IntlGetText("patientStateJaasModule.other") },
    ];
  }

  return [
    { id: 0, color: "first-impact", name: IntlGetText("patientState.pending") },
    { id: 1, color: "first-impact", name: IntlGetText("patientState.active") },
    {
      id: 2,
      color: "pat-state-1",
      name: IntlGetText("patientState.suspension"),
    },
    {
      id: 3,
      color: "pat-state-1",
      name: IntlGetText("patientState.medicalSuspension"),
    },
    { id: 4, color: "pat-state-1", name: IntlGetText("patientState.death") },
    {
      id: 5,
      color: "first-impact",
      name: IntlGetText("patientState.nonContactablePatient"),
    },
    {
      id: 6,
      color: "first-impact",
      name: IntlGetText("patientState.formulated"),
    },
    {
      id: 7,
      color: "pat-state-1",
      name: IntlGetText("patientState.voluntarySuspension"),
    },
    {
      id: 8,
      color: "pat-state-1",
      name: IntlGetText("patientState.dropout"),
    },
    {
      id: 9,
      color: "pat-state-1",
      name: IntlGetText("patientState.process"),
    }    
  ];
};

export const PatientStateParentList = () => [
  { id: 1, name: IntlGetText("patientStateParent.activeTreatment") },
  { id: 2, name: IntlGetText("patientStateParent.activeDifficulty") },
  { id: 3, name: IntlGetText("patientStateParent.activeNoBarrier") },
  { id: 4, name: IntlGetText("patientStateParent.activeDisrupted") },
  { id: 5, name: IntlGetText("patientStateParent.inactiveDiscontinued") },
  { id: 6, name: IntlGetText("patientStateParent.inactiveFinishedCycle") },
  { id: 7, name: IntlGetText("patientStateParent.inactiveOutsideProgram") },
  { id: 8, name: IntlGetText("patientStateParent.inactiveNotJoined") },
];

export const InstitutionStateList = () => [
  { id: 0, color: "#7affaf", name: "Estado del caso" },
  { id: 1, color: "#ac78ff", name: "Estado del caso" },
  { id: 2, color: "#ff3e3e", name: "Estado del caso" },
  { id: 3, color: "#ffc949", name: "Estado del caso" },
];
export const MedicalSuspensionReasonList = () => [
  { id: 0, name: IntlGetText("medicalSuspensionReason.ram") },
  { id: 1, name: IntlGetText("medicalSuspensionReason.transplant") },
  { id: 2, name: IntlGetText("medicalSuspensionReason.terapeuticFailure") },
  { id: 3, name: IntlGetText("medicalSuspensionReason.medicalDecision") }
];
export const VariableGroupTypeList = () => [
  { id: 0, name: IntlGetText("variableGroupType.clinicalHistory") },
  { id: 1, name: IntlGetText("variableGroupType.tracing") },
];
export const ClinicalFindingTypeList = () => [
  { id: 0, name: IntlGetText("clinicalFindingType.conservation") },
  { id: 1, name: IntlGetText("clinicalFindingType.medicine") },
  { id: 2, name: IntlGetText("clinicalFindingType.clinical") },
];
export const ClinicalFindingStateList = () => [
  { id: 0, name: IntlGetText("clinicalFindingState.Active") },
  { id: 1, name: IntlGetText("clinicalFindingState.closed") },
];
export const GeneralStatusList = () => [
  { id: 0, name: IntlGetText("generalStatus.ok") },
  { id: 1, name: IntlGetText("generalStatus.regular") },
  { id: 2, name: IntlGetText("generalStatus.bad") },
];
export const ImpactFileTypeList = () => [
  { id: 0, name: IntlGetText("impactFileType.remodulinCatheter") },
  { id: 1, name: IntlGetText("impactFileType.remodulinPump") },
  { id: 2, name: IntlGetText("impactFileType.visit") },
];
export const MoriskyOptionsQuestion8List = () => [
  { id: 0, name: IntlGetText("morisky.optionsQuestion8.almostNever") },
  { id: 1, name: IntlGetText("morisky.optionsQuestion8.rarely") },
  { id: 2, name: IntlGetText("morisky.optionsQuestion8.occasionally") },
  { id: 3, name: IntlGetText("morisky.optionsQuestion8.habitually") },
  { id: 4, name: IntlGetText("morisky.optionsQuestion8.forever") },
];
export const SupplyActionTypeList = (isPhg) => {
  if(isPhg){
    return [
    { id: 0, name: IntlGetText("supplyActionType.phgSupply") },
    { id: 1, name: IntlGetText("supplyActionType.phgConsumption") },
    ]
  }
  return [
    { id: 0, name: IntlGetText("supplyActionType.supply") },
    { id: 1, name: IntlGetText("supplyActionType.consumption") },
  ]
};
export const ForeamSeriousnessList = () => [
  { id: 0, name: IntlGetText("foreamSeriousness.hospitalization") },
  { id: 1, name: IntlGetText("foreamSeriousness.anomaly") },
  { id: 2, name: IntlGetText("foreamSeriousness.lifeThreat") },
  { id: 3, name: IntlGetText("foreamSeriousness.death") },
  { id: 4, name: IntlGetText("foreamSeriousness.disability") },
  { id: 5, name: IntlGetText("foreamSeriousness.medicalCondition") },
  { id: 6, name: IntlGetText("foreamSeriousness.notSerious") }
];
export const ForeamDenouementList = () => [
  { id: 0, name: IntlGetText("foreamDenouement.withoutSequelae") },
  { id: 1, name: IntlGetText("foreamDenouement.withSequelae") },
  { id: 2, name: IntlGetText("foreamDenouement.solving") },
  { id: 3, name: IntlGetText("foreamDenouement.notResolved") },
  { id: 4, name: IntlGetText("foreamDenouement.fatal") },
  { id: 5, name: IntlGetText("foreamDenouement.unknown") }
];
export const YesNoNotKnowList = () => [
  { id: 0, name: IntlGetText("core.yes") },
  { id: 1, name: IntlGetText("button.no") },
  { id: 2, name: IntlGetText("core.notKnow") },
];
export const YesNoNAList = () => [
  { id: 0, name: IntlGetText("core.yes") },
  { id: 1, name: IntlGetText("button.no") },
  { id: 2, name: IntlGetText("core.na") }
];
export const ForeiaDetectionList = () => [
  { id: 0, name: IntlGetText("foreia.detection.before") },
  { id: 1, name: IntlGetText("foreia.detection.during") },
  { id: 2, name: IntlGetText("foreia.detection.later") }
];
export const ForeiaClassificationList = () => [
  { id: 0, name: IntlGetText("foreia.classification.seriousEvent") },
  { id: 1, name: IntlGetText("foreia.classification.nonSeriousEvent") },
  { id: 2, name: IntlGetText("foreia.classification.seriousIncident") },
  { id: 3, name: IntlGetText("foreia.classification.nonSeriousIncident") }
];
export const ForeiaDenouementList = () => [
  { id: 0, name: IntlGetText("foreia.denouement.death") },
  { id: 1, name: IntlGetText("foreia.denouement.damage") },
  { id: 2, name: IntlGetText("foreia.denouement.lifeThreatening") },
  { id: 3, name: IntlGetText("foreia.denouement.requireIntervention") },
  { id: 4, name: IntlGetText("foreia.denouement.disability") },
  { id: 5, name: IntlGetText("foreia.denouement.hospitalization") },
  { id: 6, name: IntlGetText("foreia.denouement.malformation") },
  { id: 7, name: IntlGetText("foreia.denouement.noDamage") },
  { id: 8, name: IntlGetText("foreia.denouement.other") }
];
export const CiomsTypeList = () =>[
  { id: 0, name: IntlGetText("ciomsType.initial") },
  { id: 1, name: IntlGetText("ciomsType.tracing") },
];
export const SciList = () =>[
  { id: 0, name: "S" },
  { id: 1, name: "C" },
  { id: 2, name: "I" }
];
export const AdverseEventStateList = () => [
  { id: 0, name: IntlGetText("adverseEventState.pending"), color: "first-impact" },
  { id: 1, name: IntlGetText("adverseEventState.pendingToVerify"), color: "first-impact" },
  { id: 2, name: IntlGetText("adverseEventState.inconsistentReport"), color: "first-impact" },
  { id: 3, name: IntlGetText("adverseEventState.verified"), color: "first-impact" },
  { id: 4, name: IntlGetText("adverseEventState.generatedFoream"), color: "first-impact" },
  { id: 5, name: IntlGetText("adverseEventState.generatedForeia"), color: "first-impact" },
  { id: 6, name: IntlGetText("adverseEventState.sentToRegulator"), color: "first-impact" },
  { id: 7, name: IntlGetText("adverseEventState.registeredCausality"), color: "first-impact" },
  { id: 8, name: IntlGetText("adverseEventState.generatedCioms"), color: "first-impact" },
  { id: 9, name: IntlGetText("adverseEventState.sentToPartner"), color: "first-impact" },
  { id: 10, name: IntlGetText("adverseEventState.pendingQuery"), color: "first-impact" },
  { id: 11, name: IntlGetText("adverseEventState.queryVerified"), color: "first-impact" },
  { id: 12, name: IntlGetText("adverseEventState.annulled"), color: "first-impact" }
];
export const QueryAdverseEventStateList = () => [
  { id: 0, name: IntlGetText("queryAdverseEventState.new") },
  { id: 1, name: IntlGetText("queryAdverseEventState.inProgress") },
  { id: 2, name: IntlGetText("queryAdverseEventState.resolved") },
  { id: 3, name: IntlGetText("queryAdverseEventState.approved") },
  { id: 4, name: IntlGetText("queryAdverseEventState.rejected") },
];
export const QueryAdverseEventTypeList = () => [
  { id: 0, name: IntlGetText("queryAdverseEventType.pharmacovigilance") },
  { id: 1, name: IntlGetText("queryAdverseEventType.regulator") },
  { id: 2, name: IntlGetText("queryAdverseEventType.partner") }
];
export const RemodulinPumpStateList = () => [
  { id: 0, name: IntlGetText("RemodulinPumpState.New") },
  { id: 1, name: IntlGetText("RemodulinPumpState.Open") },
  { id: 2, name: IntlGetText("RemodulinPumpState.Closed") },
  { id: 3, name: IntlGetText("RemodulinPumpState.Finished") }
];

export const ReportListPint  = () => [
  { id: 1, name: IntlGetText("kpi.impacts") },
  { id: 2, name: IntlGetText("reports.controlPanelRemodulin") },
  { id: 3, name: IntlGetText("reports.controlPanelIclusig") },
  { id: 4, name: IntlGetText("reports.controlPanelGalafold") },
  { id: 5, name: IntlGetText("core.clinicHistory") },
  { id: 6, name: IntlGetText("report.medicineConsumption") },
  { id: 7, name: IntlGetText("infusionPumps.dlgTitle") },
  { id: 8, name: IntlGetText("reports.catherChange") },
  { id: 9, name: IntlGetText("reports.bcrHemograma") },
  { id: 10, name: IntlGetText("reports.pspCube") },
  { id: 11, name: IntlGetText("reports.formulaExpiration") },
  { id: 12, name: IntlGetText("reports.authorizationExpiration") },
  { id: 13, name: IntlGetText("reports.deliveryExpiration") },
  { id: 14, name: IntlGetText("reports.supplyReport") },
  { id: 15, name: IntlGetText("reports.morisky") },
  { id: 16, name: IntlGetText("reports.pendingRSVariables") },
  { id: 17, name: IntlGetText("reports.doseChange") },
  { id: 18, name: IntlGetText("reports.suspendedPatients") },
  { id: 19, name: IntlGetText("reports.externalReports") },
  { id: 20, name: IntlGetText("reports.feedbackEventTraining") },
  { id: 22, name: IntlGetText("reports.deliveries") },
  { id: 23, name: IntlGetText("reports.remodulinVials") },
  { id: 26, name: IntlGetText("reports.surveyMorisky") },
];

export const ReportListGeneric  = () => [
  { id: 1, name: IntlGetText("kpi.impacts") },
  { id: 5, name: IntlGetText("core.clinicHistory") },
  { id: 6, name: IntlGetText("report.medicineConsumption") },
  { id: 11, name: IntlGetText("reports.formulaExpiration") },
  { id: 12, name: IntlGetText("reports.authorizationExpiration") },
  { id: 13, name: IntlGetText("reports.deliveryExpiration") },
  { id: 17, name: IntlGetText("reports.doseChange") },
  { id: 18, name: IntlGetText("reports.suspendedPatients") },
  { id: 19, name: IntlGetText("reports.externalReports") },
  { id: 20, name: IntlGetText("reports.feedbackEventTraining") },
  { id: 21, name: IntlGetText("reports.patients") },
  { id: 24, name: IntlGetText("reports.woundClinic") },
  { id: 25, name: IntlGetText("reports.generalJaasiel") },
  
];

export const ReportEnum = {
  impacts: 1,
  controlPanelRemodulin:2,
  controlPanelIclusig:3,
  controlPanelGalafold:4,
  clinicHistory:5,
  medicineConsumption:6,
  remodulinPumps:7,
  catherChange:8,
  bcrHemograma:9,
  pspCube:10,
  formulaExpiration:11,
  authorizationExpiration:12,
  deliveryExpiration:13,
  supplyReport:14,
  morisky:15,
  pendingRSVariables:16,
  doseChange:17,
  suspendedPatients:18,
  externalReports:19,
  feedbackEventTraining:20,
  patientsReport:21,
  deliveries:22,
  remodulinVials:23,
  woundClinic:24,
  generalJaasiel:25,
  surveyMorisky:26,
  behringerReport:32
};

export const DeliveryModeList = () =>[
  { id: 1, name: IntlGetText("deliveryMode.personal") },
  { id: 2, name: IntlGetText("deliveryMode.domicilio") }
];

export const ReportList  = () => [
  { id: 1, name: IntlGetText("core.prescriptions") },
  { id: 2, name: IntlGetText("insuranceAuthorization.deliveries") },
  { id: 3, name: IntlGetText("kpi.impacts") },
  { id: 4, name: IntlGetText("core.callAgent") },
];

export const ReportListEnum = {
  prescriptions:1,
  deliveries:2,
  impacts:3,
  callAgent:4
};

export const TrainingResourceTypeList = () =>[
  { id: 0, name: IntlGetText("trainingResourceType.video") },
  { id: 1, name: IntlGetText("core.file") },
];
export const TrainingResourceTypeEnum = {
  video:0,
  file:1
}
export const TrainingStateList = () =>[
  { id: 0, name: IntlGetText("impactState.planning"), color: "first-impact" },
  { id: 1, name: IntlGetText("trainingState.done"), color:"presc"},
];
export const TrainingStateEnum = {
  planned:0,
  done:1
}

export const RoleList = () =>[
  { id: "NURSE", name: IntlGetText("role.nurse") },
  { id: "PHARMACOVIGILANCE", name: IntlGetText("role.pharmacovigilance")},
  { id: "PSP_MANAGER", name: IntlGetText("role.psp_manager")},
  { id: "VISITOR", name: IntlGetText("role.visitor")},
  { id: "KAM", name: IntlGetText("role.kam")},
  { id: "CALL_AGENT", name: IntlGetText("role.callAgent")},
  { id: "CALL_AGENT_MANAGER", name: IntlGetText("role.callAgentManager")},
  { id: "REP", name: IntlGetText("role.representative")},
  { id: "SUPERADMIN", name: "SUPERADMIN"},
  { id: "INSTITUTION", name: IntlGetText("role.institution")},
  { id: "INSTITUTION_MANAGER", name: IntlGetText("role.institutionManager")},
  { id: "PSP_COORDINATOR", name: IntlGetText("role.pspCoordinator")},
  { id: "MSL", name: IntlGetText("role.msl")},  
  { id: "CONSULTATION", name: IntlGetText("role.consultation")},
  { id: "NURSE_MANAGER_CONSULTANT", name: IntlGetText("role.nursemanagerconsultant")},
  { id: "NURSE_PROCEDURE", name: IntlGetText("role.nurseProcedure")},
];
export const RoleEnum = {
  nurse: "NURSE",
  pharmacovigilance: "PHARMACOVIGILANCE",
  psp_manager: "PSP_MANAGER",
  manager: "MANAGER",
  pharma: "PHARMA",
  visitor: "VISITOR",
  kam: "KAM",
  callAgent: "CALL_AGENT",
  callAgentManager: "CALL_AGENT_MANAGER",
  rep: "REP",
  institution: "INSTITUTION",
  superAdmin: "SUPERADMIN",
  institutionManager: "INSTITUTION_MANAGER",
  psp_coordinator: "PSP_COORDINATOR",
  msl: "MSL",  
  consultation: "CONSULTATION",
  nursemanagerconsultant: "NURSE_MANAGER_CONSULTANT",
  kpi_survey: "KPI_SURVEY",
  patient_assignment: "PATIENT_ASSIGNMENT",
  nurse_procedure: "NURSE_PROCEDURE",
};

export const SettingsEnum = {
  onlyTracing: "PSP_ONLY_TRACING",
  jaasielModule: "PSP_JAAS_MODULE",
  reportMorisky: "PSP_REPORT_MORISKY",
  hideBoxes: "PSP_HIDE_BOXES",
};

//Insitution context. Patient Institutionstate
export const PatientInstitutionStateEnum = {
  none: 1,
  stated: 2,
  finished: 3
};
export const PatientInstitutionStateList = () =>[
  { id: 1, name: "" },
  { id: 2, name: IntlGetText("institutionState.stated")},
  { id: 3, name: IntlGetText("institutionState.finished")},
];

///** */
export const PrescripStateEnum = {
  pending: 0,
  doctorAccepted: 1,
  patientAccepted: 2,
  fristImpact: 3,
  formulated: 4,
  formulatedWithErrors: 5,
  radicacionEPS: 6,
  radicacionEPSNonEffective: 7,
  authorized: 8,
  noAuthorized: 9,
  authorizationWithInconsistencies: 10,
  radicacionOLF: 11,
  radicacionOLFNonEffective: 12,
  delivered: 13,
  incompleteDelivery: 14,
  deliveryNotMade: 15,
  cancelled: 16,
  patientOutOfProgram: 17,
  completedSuccessfully: 18,
  Invalidated: 19,
  NewFormulation: 20,
  forcedTermination: 21
};
export const ImpactStateEnum = {
  planning: 0,
  registered: 1,
  cancelled: 2,
  inProgress : 3
};
export const ImpactFileType = {
  formula: 0,
  authorization: 1,
};
export const FolderType = {
  newPatients: 0,
  inProcess: 1,
  tracing: 2,
  adverseEvents: 3,
  trainingsAndEvents: 4,
};
export const FormulaType = {
  MIPRES: 0,
  NoMIPRES: 1,
};
export const PrescriptionType = {
  Continuous: 0,
  Unique: 1,
};
export const RadicadoInsuranceState = {
  pending: 0,
  authorized: 1,
  noAuthorized: 2,
  authorizationWithInconsistencies: 3,
};
export const ProductBehavior = {
  remodulin: 0,
  iclusig: 1,
  galafold: 2,
  dacepton: 3
};
export const RadicadoLogisticOperatorState = {
  done: 0,
  deliveryNotMade: 1,
};
export const AlertStateEnum = {
  pending: 0,
  done: 1,
  inProgress: 2,
  notResolved: 3,
};
export const AlertTypeEnum = {
  newPatient: 0,
  doctorDidNotAccept: 1,
  patientDidNotAccept: 2,
  formulatedWithErrors: 3,
  radicacionEPSNonEffective: 4,
  authorizationDelays: 5,
  noAuthorized: 6,
  authorizationWithInconsistencies: 7,
  radicacionOLFNonEffective: 8,
  incompleteDelivery: 9,
  deliveryNotMade: 10,
  cancelled: 11,
  newFomurlationCycle: 12,
};
export const ControlTypeEnum = {
  text: 1,
  dateTime: 2,
  checkbox: 3,
  select: 4,
  number:5
};
export const PatientStateEnum = {
  pending: 0,
  active: 1,
  suspension: 2,
  medicalSuspension: 3,
  death: 4,
  nonContactablePatient: 5,
  formulated: 6,
  voluntarySuspension: 7,
  dropout:8,
  process:9
};
export const ClinicalFindingStateEnum = {
  active: 0,
  closed: 1,
};
export const ImpactFileTypeEnum = {
  remodulinCatheter: 0,
  remodulinPump: 1,
  visit: 2,
};
export const MoriskyOptionsQuestion8Enum = {
  almostNever: 0,
  rarely: 1,
  occasionally: 2,
  habitually: 3,
  forever: 4,
};
export const AdverseEventTypeEnum = {
  pharmacovigilance: 1,
  technovigilance: 2
};
export const AdverseEventStateEnum = {
  pending: 0,
  pendingToVerify: 1,
  inconsistentReport: 2,
  verified: 3,
  generatedFoream: 4,
  generatedForeia: 5,
  sentToRegulator: 6,
  registeredCausality: 7,
  generatedCioms: 8,
  sentToPartner: 9,
  pendingQuery: 10,
  queryVerified: 11,
  annulled: 12
};
export const QueryAdverseEventStateEnum = {
  neww: 0,
  inProgress: 1,
  resolved: 2,
  approved: 3,
  rejected: 4,
};
export const QueryAdverseEventTypeEnum = {
  pharmacovigilance: 0,
  regulator: 1,
  partner: 2
};
export const FormatTypeEnum = {
  foream:0,
  cioms:1,
  foreia:2
};
export const CiomsTypeEnum = {
  initial:0,
  tracing:1,
};

export const ForeiaDenouementEnum = {
  death: 0,
  damage: 1,
  lifeThreatening: 2,
  requireIntervention: 3,
  disability: 4,
  hospitalization: 5,
  malformation: 6,
  noDamage: 7,
  other: 8
};

export const ExpirationReportTypeEnum = {
  formula:0,
  authorization:1,
  delivery:2
};

export const PlanningTypeEnum = {
  impact:0,
  eventTraining:1
}
export const UserStatePAEnum = {
  active: 1,
  inactive: 2,
  pendingVerification: 3,
  preRegister: 4
}

export const ShardKeyEnum = {
  ialuril: "66",
  pintPharma: "63",
  ullkupuy: "130",
  boidor: "62",
  cmedi: "110",
  phg: "116",
  jaasielvalentech: "121",
  sanando: "130",
  jaasielmegalabs: "131",
  jaasielcipla: "132",
  jaasielsophia: "134",
  jaasielprocaps: "135",
  jaasielgt: "136",
  jaasielhb: "137",
  jaasielaltadis: "140",
  akompaname: "149",
  jaasielServier: "151",
  atulado: "156",
  recordati: "163",
  operando: "166",
  behringer: "156",
  celltrion: "177",
}

export const PatientOperationEnum = {
  add: 1,
  create: 2
}

export const PatientFinishedStateList = () =>[
  { id: 1, name: IntlGetText("institutionStateFinished.woundClosure")},
  { id: 2, name: IntlGetText("institutionStateFinished.withDrawal")},
  { id: 3, name: IntlGetText("institutionStateFinished.death")},
  { id: 4, name: IntlGetText("institutionStateFinished.treatmentChange")},
];

export const PatientDistributionByEpsAndStateEnum = {
  add: 1,
  create: 2,
  create: 3,
  create: 4,
  create: 5,
}

export const PatientDistributionByEpsAndStateList = () =>[
  { id: 1, name: IntlGetText("core.active")},
  { id: 2, name: IntlGetText("institutionState.stated")},
  { id: 3, name: IntlGetText("core.suspended")},
  { id: 4, name: IntlGetText("core.retired")},
  { id: 5, name: IntlGetText("queryAdverseEventState.new")},
];

export const PatientJaasModuleEnum = {
  SUSPENDED: 1,
  RETIRED: 2,
  ACTIVE: 3,
  IMPOSSIBLE_CONTACT: 4,
}

export const BarrierFlagEnum = {
  ACTIVE_INACTIVE: 1,
  INACTIVE_ACTIVE: 2,
  INACTIVE_INACTIVE: 3,
}