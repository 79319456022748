import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { SketchField, Tools } from "react-sketch";
import { useDispatch } from "react-redux";
import { setLoader } from "actions/Base";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Grid, DialogContentText } from "@material-ui/core";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import FormInput from "components/Form/Controls/input";
import DialogAsync from "components/Form/DialogAsync";
import FormDateTimePicker from "components/Form/Controls/dateTimePicker";
import InputFile from "components/Form/inputFile";
import FormSelectAutoComplete, { getCboValue } from "components/Form/Controls/select-autocomplete";
import { callApiAsync, postIntitutionEndImpact } from "api/apiList";
import { PatientInstitutionStateList, PatientInstitutionStateEnum , PatientFinishedStateList} from "constants/Enums";

const EndingForm = ({ openDlg, closeDialog, impactInfo, setImpactInfo, impactRow, setStateFinished }) => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const [patientSign, setPatientSign] = useState(null);
  const [nurseSign, setNurseSign] = useState(null);
  const [fileResult, setFileResult] = useState({fileName:"", originalName:""});
  const [signOpt, setSignOpt] = useState(1);
  const [exitState, setExitState] = useState(2);
  
  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgPatientSignRequired = IntlGetText("institution.patientSignRequired");
  const msgNurseSignRequired = IntlGetText("institution.nurseSignRequired");
  const msgPendingSignatureFile = IntlGetText("core.pendingSignatureFile");

  const patientSignatureOptions = [
    {id:1, name: IntlGetText("institution.patientSignature.sign")},
    {id:2, name: IntlGetText("institution.patientSignature.uploadFile")}
  ];
  const patientStateList = PatientInstitutionStateList().filter(x=>x.id == PatientInstitutionStateEnum.stated || x.id == PatientInstitutionStateEnum.finished);
  const patientFinishedStateList = PatientFinishedStateList();

  const defaultValues = {
    endingDateAndTime: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    state: !impactRow ? null : getCboValue(patientStateList, impactRow.stateId),
    patientCC: "",
    nurseCC: "",
    nurseNotes: "",
    exitReason:"",
    patientSignatureOption: getCboValue(patientSignatureOptions, 1),
    signedDocument: null
  };

  let validationSchema = {
    endingDateAndTime: yup
      .string()
      .required(<IntlMessages id="core.fieldRequired" />),
    nurseNotes: yup.string().required(<IntlMessages id="core.fieldRequired" />),
  };
  if(signOpt === 1){
    validationSchema.patientCC= yup.string().required(<IntlMessages id="core.fieldRequired" />);
    validationSchema.nurseCC= yup.string().required(<IntlMessages id="core.fieldRequired" />);
  }
   if(exitState === 3){
    validationSchema.exitReason= yup.object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable()
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, setValue, errors, watch } = methods;

  const patientSignatureOptValue = watch("patientSignatureOption");
  useEffect(()=>{
    if(!patientSignatureOptValue){
      setSignOpt(1);
      return;
    }
    var value = patientSignatureOptValue.value;
    if(value !== signOpt)
      setSignOpt(value);
  },[patientSignatureOptValue]);

  const exitOptValue = watch("state");
  useEffect(()=>{
    if(!exitOptValue){
      setExitState(2);
      return;
    }
    var value = exitOptValue.value;
    if(value !== signOpt)
      setExitState(value);
  },[exitOptValue]);

  useEffect(()=>{
    if(!openDlg)
      return;
    
    setTimeout(() => {
      setValue("patientCC", !impactInfo || !impactInfo.patientDocument ? "" : impactInfo.patientDocument);
      //setValue("patientSignatureOption", getCboValue(patientSignatureOptions, 1));
    }, 200);
  },[openDlg, impactInfo]);

  const onSubmit = handleSubmit(async (d) => {
    let obj = {
        id: impactInfo.id,
        endDate: dayjs(d.endingDateAndTime).format("YYYY-MM-DDTHH:mm:ss"),
        nursingNote: d.nurseNotes,
        state: Number(d.state.value),
        exitReason: d.exitReason?Number(d.exitReason.value):null,
    };

    if(patientSignatureOptValue && patientSignatureOptValue.value === 1){
      if (!patientSign || !patientSign.canUndo()) {
        NotificationManager.warning(msgPatientSignRequired);
        return;
      }
      if (!nurseSign || !nurseSign.canUndo()) {
        NotificationManager.warning(msgNurseSignRequired);
        return;
      }

      obj = {
        ...obj,
        patientDocument: d.patientCC,
        nurseDocument: d.nurseCC,
        patientSignature: patientSign.toDataURL(),
        nurseSignature: nurseSign.toDataURL()
      };
    }
    else{
      if (!fileResult || !fileResult.fileName) {
        NotificationManager.warning(msgPendingSignatureFile);
        return;
      }

      obj = {
        ...obj,
        signedDocument: fileResult.fileName
      };
    }

    const isFinished = obj.state === PatientInstitutionStateEnum.finished;
    await callApiAsync(
      () => postIntitutionEndImpact(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setImpactInfo(data);
        closeDialog();
        if(isFinished)
          setStateFinished(true);
      },
      fnSetLoader
    );
    
  });

  return (
    <div>
      <DialogAsync
        open={openDlg}
        closeDialog={closeDialog}
        onSubmitAsync={onSubmit}
        titleTextId={"institution.ending"}
        allowEdit={true}
      >
        <FormProvider {...methods}>
          <form className="row" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FormDateTimePicker
                  name="endingDateAndTime"
                  label={IntlGetText("core.endingDateAndTime")}
                  format="YYYY-MM-DD hh:mm A"
                  disableFuture={true}
                  leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                  rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="state"
                  label={<IntlMessages id="core.state" />}
                  options={patientStateList}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {watch("state").value  == PatientInstitutionStateEnum.finished &&
                <Grid item xs={12} md={12} lg={12}>
                  <FormSelectAutoComplete
                    name="exitReason"
                    label={<IntlMessages id="institutionStateFinished.exitReason"/>}
                    options={patientFinishedStateList}
                    margin="normal"
                    required={true}
                    errorobj={errors}
                  />
               </Grid>
              }
              <Grid item xs={12} md={12} lg={12}>
                <FormSelectAutoComplete
                  name="patientSignatureOption"
                  label={<IntlMessages id="ci.signature" />}
                  options={patientSignatureOptions}
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              {patientSignatureOptValue && patientSignatureOptValue.value === 1 && 
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <DialogContentText>
                    <IntlMessages id="institution.PatientSignature" />
                  </DialogContentText>
                  <div
                    className="text-center"
                    style={{ border: "1px solid lightgray" }}
                  >
                    <SketchField
                      ref={(c) => setPatientSign(c)}
                      width="100%"
                      height="100px"
                      tool={Tools.Pencil}
                      lineColor="black"
                      lineWidth={2}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="patientCC"
                    label={<IntlMessages id="institution.PatientCC" />}
                    margin="normal"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <DialogContentText>
                    <IntlMessages id="institution.NurseSignature" />
                  </DialogContentText>
                  <div
                    className="text-center"
                    style={{ border: "1px solid lightgray" }}
                  >
                    <SketchField
                      ref={(c) => setNurseSign(c)}
                      width="100%"
                      height="100px"
                      tool={Tools.Pencil}
                      lineColor="black"
                      lineWidth={2}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="nurseCC"
                    label={<IntlMessages id="institution.NurseCC" />}
                    margin="normal"
                    errorobj={errors}
                  />
                </Grid>
              </>
              }
              {patientSignatureOptValue && patientSignatureOptValue.value === 2 && 
                <Grid item xs={12} md={12} lg={12}>
                  <InputFile
                    name="signedDocument"
                    fileResult={fileResult}
                    setFileResult={(f)=>{
                      setFileResult(f);
                      setValue("signedDocument",f.fileName);
                    }}
                    errors={errors}/>
                </Grid>
              }
              <Grid item xs={12} md={12} lg={12}>
                <FormInput
                  label={<IntlMessages id="institution.nurseNotes" />}
                  name="nurseNotes"
                  multiline
                  rows={3}
                  variant="outlined"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogAsync>
    </div>
  );
};

export default EndingForm;
