import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import InfoCard from "components/InfoCard/index";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import {
  callApi,
  callApiAsync,
  getNursingPlans,
  postCreateUpdateNursingPlan,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";

const NursePlanForm = ({ nursingPlan, setNursingPlan, handleRequestClose }) => {
  const msgSuccess = IntlGetText("core.SuccessSave");
  
  const defaultValues = {
    diagnostic: !nursingPlan ? "" : nursingPlan.diagnostic,
    goal: !nursingPlan ? "" : nursingPlan.goal,
    activities: !nursingPlan ? "" : nursingPlan.activities,
  };
  const validationSchema = yup.object().shape({
    diagnostic: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    goal: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    activities: yup.string().required(<IntlMessages id="core.fieldRequired" />),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, errors } = methods;

  const onSubmit = async (d, e) => {
    const obj = {
      id: nursingPlan.id,
      impactId: nursingPlan.impactId,
      diagnostic: d.diagnostic,
      goal: d.goal,
      activities: d.activities,
      evaluationResultsDate: null,
      evaluationResults: null,
    };

    await callApiAsync(
      () => postCreateUpdateNursingPlan(obj),
      (data) => {
        NotificationManager.success(msgSuccess);
        setNursingPlan(data[0]);
        handleRequestClose();
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
        <form
          className="row nursing-plan-box-margins"
          noValidate
          autoComplete="off"
        >
          <Grid item xs={12} md={12} lg={12}>
            <FormInput
              label={<IntlMessages id="psp.nurseplan.diagnose" />}
              name="diagnostic"
              required={true}
              multiline
              rows={3}
              variant="outlined"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormInput
              label={<IntlMessages id="psp.nurseplan.goal" />}
              name="goal"
              required={true}
              multiline
              rows={3}
              variant="outlined"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormInput
              label={<IntlMessages id="psp.nurseplan.activity" />}
              name="activities"
              required={true}
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              errorobj={errors}
            />
          </Grid>
        </form>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-4">
          <ButtonAsync onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            <IntlMessages id="core.save" />
          </ButtonAsync>
        </div>
      </div>
    </FormProvider>
  );
};

const NursePlan = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired } = useSelector(({ impact }) => impact);

  const [open, setOpen] = useState(false);
  const [nursingPlan, setNursingPlan] = useState(null);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  useEffect(() => {
    callApi(
      () => getNursingPlans(selectedPrescription.id),
      (data) => {
        setNursingPlan(
          data.length > 0
            ? data[0]
            : {
                id: 0,
                impactId: selectedPrescription.impactId,
              }
        );
      }
    );
  }, []);

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentPath){
        NotificationManager.warning(msgConsentRequired);
        return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "file-text",
          title: IntlGetText("core.plan"),
          subTitle: IntlGetText("core.nursing"),
          color: nursingPlan == null || nursingPlan.id == 0 ? "#E53935" : "#20c997",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="core.nursingPlan" />
        </DialogTitle>
        <DialogContent>
          <NursePlanForm
            nursingPlan={nursingPlan}
            setNursingPlan={setNursingPlan}
            handleRequestClose={handleRequestClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NursePlan;
