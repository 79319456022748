import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlGetText } from "util/IntlMessages";
import {
  FolderType,
  PatientStateList,
  AdverseEventStateList,
  TrainingStateList,
} from "constants/Enums";
import { isJaasModule } from "app/routes/psp/services/userService";
import { callApi, getPatientStateList } from "api/apiList";

const LabelsSection = ({ prescription }) => {
  const { selectedMenuItem, productLines, prescriptionStates } = useSelector(
    ({ management }) => management
  );
  const [patientStateList, setPatientStateList] = useState([]);
  const adverseEventStateList = AdverseEventStateList();
  const trainingStateList = TrainingStateList();

  const txtOnTime = IntlGetText("adverseEventTime.onTime");
  const txtDelayed = IntlGetText("adverseEventTime.delayed");
  const txtFollowUp = IntlGetText("adverseEvent.followUp");

  const patientStateList2 = PatientStateList();

  useEffect(() => {
    if (isJaasModule()) {
      callApi(getPatientStateList, (data) => {
        setPatientStateList(data.states);
      });
    } else {
      setPatientStateList(patientStateList2);
    }
  }, []);

  const ShowAdverseEventTime = (value) => {
    if (value === null) return "";
    return value ? (
      <div className={" badge text-uppercase text-white bg-green"}>
        {txtOnTime}
      </div>
    ) : (
      <div className={" badge text-uppercase text-white bg-red"}>
        {txtDelayed}
      </div>
    );
  };

  const ShowEventTrainingState = () => {
    if (
      selectedMenuItem === FolderType.trainingsAndEvents &&
      prescription &&
      prescription.eventTraining
    ) {
      const item = trainingStateList.find(
        (x) => x.id === prescription.eventTraining.state
      );
      if (item) {
        return (
          <div className={`badge text-uppercase text-white bg-${item.color}`}>
            {item.name}
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <div className="labels">
      {productLines.map((productLine, index) => {
        return (
          prescription.productLineId === productLine.id && (
            <div
              key={index}
              className={`badge text-uppercase text-white bg-${productLine.color}`}
            >
              {productLine.title}
            </div>
          )
        );
      })}
      {selectedMenuItem === FolderType.tracing &&
        prescription.patient &&
        patientStateList.map((state, index) => {
          return (
            prescription.patient.state === state.id && (
              <div
                key={index}
                className={`badge text-uppercase text-white bg-${state.color}`}
              >
                {state.name}
              </div>
            )
          );
        })}
      {selectedMenuItem === FolderType.adverseEvents &&
        prescription &&
        prescription.adverseEvent &&
        prescriptionStates.map((state, index) => {
          return (
            state.type === selectedMenuItem &&
            prescription.adverseEvent.adverseEventTypeId === state.id && (
              <div
                key={index}
                className={`badge text-uppercase text-white bg-${state.color}`}
              >
                {state.title +
                  (!prescription.adverseEvent.followUpId
                    ? ""
                    : " (" + txtFollowUp + ")")}
              </div>
            )
          );
        })}
      {selectedMenuItem === FolderType.adverseEvents &&
        prescription &&
        prescription.adverseEvent &&
        adverseEventStateList.map((state, index) => {
          return (
            prescription.adverseEvent.state === state.id && (
              <div
                key={index}
                className={`badge text-uppercase text-white bg-${state.color}`}
              >
                {state.name}
              </div>
            )
          );
        })}
      {selectedMenuItem === FolderType.adverseEvents &&
        prescription &&
        prescription.adverseEvent &&
        ShowAdverseEventTime(prescription.adverseEvent.isOnTime)}
      {prescriptionStates
        .filter((x) => !x.isImpactType)
        .map((state, index) => {
          return (
            state.type === selectedMenuItem &&
            prescription.stateId === state.id && (
              <div
                key={index}
                className={`badge text-uppercase text-white bg-${state.color}`}
              >
                {state.title}
              </div>
            )
          );
        })}
      {prescriptionStates
        .filter((x) => x.isImpactType)
        .map((state, index) => {
          return (
            state.type === selectedMenuItem &&
            prescription.impactTypeId === state.id && (
              <div
                key={index}
                className={`badge text-uppercase text-white bg-${state.color}`}
              >
                {state.title}
              </div>
            )
          );
        })}
      <ShowEventTrainingState />
    </div>
  );
};

export default LabelsSection;
