import React, { useState, useEffect } from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogPlanningImpact from "./DialogPlanningImpact";
import DialogRescheduleImpact from "./DialogRescheduleImpact";
import DialogImpactInfo from "./DialogImpactInfo";
import DlgEventTraining from "./dlgEventTraining";
import { getRoleUser, isRole } from "app/routes/psp/services/userService";
import { ReactSelect } from "components/Form/Controls/select-autocomplete";
import {
  callApi,
  callApiAsync,
  getPlannedImpacts,
  getPlannedImpactsByNurseId,
  getUserByRole,
  postPlanImpact,
  getPrescriptionsForPlanning,
  GetCommentsByType,
  postRescheduleImpact,
  postAddUpdateEventTraining,
} from "api/apiList";
import {
  ImpactStateList,
  ImpactStateEnum,
  PlanningTypeEnum,
  RoleEnum,
} from "constants/Enums";

Date.prototype.addHours = function(h) {
  this.setHours(this.getHours() + h);
  return this;
};

const getImpactState = (state, impactStateList) => {
  var stateItem = impactStateList.find((x) => x.id == state);
  return stateItem ? stateItem.name : "";
};

const getEvents = (data, impactStateList) => {
  return data.map((x) => {
    if (x.type === PlanningTypeEnum.eventTraining) {
      return {
        title: x.name,
        allDay: false,
        impactState: "training",
        start: new Date(x.planningDate),
        end: new Date(x.planningDate).addHours(1),
        ...x,
      };
    }

    return {
      title: `${x.patient} (${x.patientCode}) [${getImpactState(
        x.state,
        impactStateList
      )}]- ${x.impactType}`,
      impactState: getImpactState(x.state, impactStateList),
      allDay: false,
      start: new Date(x.planningDate),
      end: new Date(x.planningDate).addHours(1),
      ...x,
    };
  });
};
const getNurses = (data) => {
  return data.map((x) => ({
    value: x.id,
    label: x.fullName,
  }));
};
const localizer = momentLocalizer(moment);

const CalendarPlanning = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDlgInfo, setOpenDlgInfo] = useState(false);
  const [openDlgTraining, setOpenDlgTraining] = useState(false);
  const [currentEvt, setCurrentEvent] = useState({ title: "" });
  const [currentNurseId, setCurrentNurseId] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [nurseList, setNurseList] = useState([]);
  const [
    prescriptionsForPlanningList,
    setPrescriptionsForPlanningList,
  ] = useState([]);
  const [commentsList, setCommentsList] = useState([]);

  const [openDlgPlanning, setOpenDlgPlanning] = useState(false);

  const impactStateList = ImpactStateList();

  const isPSPCoordinator = isRole(RoleEnum.psp_coordinator);
  const IsPspManager = () => {
    const roleUser = getRoleUser();
    return roleUser.role === "psp_manager";
  };

  const loadImpacts = () => {
    if (!IsPspManager() && !isPSPCoordinator) {
      callApi(getPlannedImpacts, (data) => {
        setEventList(getEvents(data, impactStateList));
      });
    } else {
      if (currentNurseId) {
        callApi(
          () => getPlannedImpactsByNurseId(currentNurseId),
          (data) => {
            setEventList(getEvents(data, impactStateList));
          }
        );
      } else {
        setEventList([]);
      }
    }
  };

  const loadNurses = () => {
    callApi(
      () => getUserByRole(RoleEnum.nurse),
      (data) => {
        setNurseList(getNurses(data));
      }
    );
  };

  const HandleNurseChange = (selectedOption) => {
    setCurrentNurseId(selectedOption ? selectedOption.value : null);
  };
  useEffect(loadImpacts, [currentNurseId]);

  useEffect(() => {
    !IsPspManager() && !isPSPCoordinator ? loadImpacts() : loadNurses();
    callApi(getPrescriptionsForPlanning, setPrescriptionsForPlanningList);
    callApi(() => GetCommentsByType(0), setCommentsList);
  }, []);

  const handleRequestCloseDlgPlanning = () => setOpenDlgPlanning(false);

  const callPlanImpact = async (obj) => {
    await callApiAsync(
      () => postPlanImpact(obj),
      (data) => {
        handleRequestCloseDlgPlanning();
        loadImpacts();
      }
    );
  };

  const callRescheduleImpact = (obj) => {
    callApi(
      () => postRescheduleImpact(obj),
      (data) => {
        setOpenDialog(false);
        loadImpacts();
      }
    );
  };

  const handleEventTraining = async (obj) => {
    await callApiAsync(
      () => postAddUpdateEventTraining(obj),
      (data) => {
        setOpenDlgTraining(false);
        setCurrentEvent({ title: "" });
        loadImpacts();
      }
    );
  };

  return (
    <div className="app-wrapper">
      <div className="app-calendar app-cul-calendar animated slideInUpTiny animation-duration-3">
        <div className="row">
          <div className="col-md-12 col-12 text-right">
            <div
              className="col-md-2 col-sm-12 col-2 text-left"
              style={{ paddingRight: 0 }}
            >
              {(IsPspManager() || isPSPCoordinator) && (
                <>
                  <IntlMessages id="impact.selectNurse" />
                  <ReactSelect
                    options={nurseList}
                    onChange={HandleNurseChange}
                  />
                </>
              )}
            </div>
            {!IsPspManager() && !isPSPCoordinator && (
              <Button
                variant="contained"
                onClick={() => setOpenDlgPlanning(true)}
                className="jr-btn jr-btn-md text-white bg-cyan"
              >
                <IntlMessages id="newImpact.btnText" />
                <i className={`zmdi zmdi-file-text zmdi-hc-fw`} />
              </Button>
            )}
            {IsPspManager() && (
              <Button
                variant="contained"
                onClick={() => setOpenDlgTraining(true)}
                className="jr-btn jr-btn-md text-white bg-cyan"
              >
                <IntlMessages id="core.addTraining" />
              </Button>
            )}

            <DialogPlanningImpact
              open={openDlgPlanning}
              handleRequestClose={handleRequestCloseDlgPlanning}
              prescriptionList={prescriptionsForPlanningList}
              callPlanImpact={callPlanImpact}
            />
          </div>
          <div className="col-md-12 col-12">
            <br></br>
            <Calendar
              localizer={localizer}
              events={eventList}
              defaultDate={new Date()}
              messages={{
                date: IntlGetText("calendar.messages.date"),
                time: IntlGetText("calendar.messages.time"),
                event: IntlGetText("calendar.messages.event"),
                allDay: IntlGetText("calendar.messages.allDay"),
                week: IntlGetText("calendar.messages.week"),
                work_week: IntlGetText("calendar.messages.work_week"),
                day: IntlGetText("calendar.messages.day"),
                month: IntlGetText("calendar.messages.month"),
                previous: IntlGetText("calendar.messages.previous"),
                next: IntlGetText("calendar.messages.next"),
                yesterday: IntlGetText("calendar.messages.yesterday"),
                tomorrow: IntlGetText("calendar.messages.tomorrow"),
                today: IntlGetText("calendar.messages.today"),
                agenda: IntlGetText("calendar.messages.agenda"),
                noEventsInRange: IntlGetText(
                  "calendar.messages.noEventsInRange"
                ),
                showMore: function(e) {
                  return "+" + e + " mas";
                },
              }}
              onSelectEvent={(event) => {
                console.log("event.impactState", event.impactState);
                if (event.type === PlanningTypeEnum.eventTraining) {
                  setOpenDlgTraining(true);
                } else {
                  if (event.state == ImpactStateEnum.planning) {
                    setOpenDialog(true);
                  } else {
                    setOpenDlgInfo(true);
                  }
                }
                setCurrentEvent(event);
              }}
              eventPropGetter={(event) => ({
                className: ` ${event.colorState}-color`,
              })}
            />
            <DialogRescheduleImpact
              open={openDialog}
              handleRequestClose={() => setOpenDialog(false)}
              commentsList={commentsList}
              impact={currentEvt}
              callRescheduleImpact={callRescheduleImpact}
              readOnly={false}
            />
            <DialogImpactInfo
              open={openDlgInfo}
              handleRequestClose={() => setOpenDlgInfo(false)}
              commentsList={commentsList}
              impact={currentEvt}
            />
            <DlgEventTraining
              open={openDlgTraining}
              handleRequestClose={() => {
                setOpenDlgTraining(false);
                setCurrentEvent({ title: "" });
              }}
              event={currentEvt}
              handleEventTraining={handleEventTraining}
              readOnly={!IsPspManager()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPlanning;
