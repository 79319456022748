import React from "react";
import IntlMessages from "util/IntlMessages";
import { Grid } from "@material-ui/core";

const Indicator = ({ indicatorInfo }) => {
  const getIndicador = (prop) => {
    if (!indicatorInfo) return "0";
    var p = indicatorInfo[prop];
    if (!p && p !== 0) return "0";
    return p;
  };
  
  const getRemainder = () => {
    const total = getIndicador("medicineQuantity");
    if(!total)
      return 0;

    const consumption = getIndicador("consumption");
    if(!consumption)
      return total ? total : 0;

    return (total - consumption);
  };

  return (
    <Grid container spacing={2} className="mb-3">
      <Grid item xs={12} md={6} lg={6}>
        <label>
          <strong>
            <IntlMessages id="formula.medicineAmount" />
            {":"}
          </strong>
          {"    " + getIndicador("medicineQuantity")}
        </label>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <label>
          <strong>
            <IntlMessages id="medicineConsumption.cardTitle" />
            {":"}
          </strong>
          {"    " + getIndicador("consumption")}
        </label>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <label>
          <strong>
            <IntlMessages id="medicineConsumption.indicator.remainder" />
            {":"}
          </strong>
          {"    " + getRemainder()}
        </label>
      </Grid>
    </Grid>
  );
};

export default Indicator;
