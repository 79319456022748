import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import DialogAlert from "components/Form/DialogAlert";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  callActionApi,
  getRadicadoOLFList,
  postCreateUpdateRadicadoOLF,
  deleteRadicadoOLF,
  getInsuranceAuthorization,
  postGenerateAlert,
} from "api/apiList";
import { setPrescriptionState } from "actions/Management";
import { callStartImpact, callReloadAlerts } from "actions/Impact";
import { PrescripStateEnum } from "constants/Enums";

const existRadicacionOLFInImpact = (impactId, radicaciones) =>
  radicaciones.find((x) => x.impactId == impactId) != undefined;

const InsuranceAuthorizationDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired } = useSelector(({ impact }) => impact);

  const [authorizations, setAuthorizations] = useState([]);
  const [radicadoOLFList, setRadicadoOLFList] = useState([]);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDlgNoRadicado, setOpenDlgNoRadicado] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentPath){
        NotificationManager.warning(msgConsentRequired);
        return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  const loadRadicadOLF = () => {
    callApi(
      () => getRadicadoOLFList(selectedPrescription.id),
      setRadicadoOLFList
    );
  };

  useEffect(() => {
    loadRadicadOLF();
    callApi(
      () => getInsuranceAuthorization(selectedPrescription.id),
      setAuthorizations
    );
  }, []);

  useEffect(() => {
    if(open) 
      loadRadicadOLF();
  }, [open]);

  const handleCreateUpdate = async (data) => {
    var isNew = data.id == 0;
    await callApiAsync(
      () => postCreateUpdateRadicadoOLF(data),
      (newData) => {
        setRadicadoOLFList(newData);
        if (
          isNew &&
          (selectedPrescription.stateId ==
            PrescripStateEnum.radicacionOLFNonEffective ||
            selectedPrescription.stateId == PrescripStateEnum.deliveryNotMade ||
            selectedPrescription.stateId ==
              PrescripStateEnum.incompleteDelivery)
        ) {
          dispatch(setPrescriptionState(PrescripStateEnum.radicacionOLF));
        }
        var objId =
          data.id > 0 ? data.id : Math.max(...newData.map((x) => x.id));
        setCurrentRowObj(newData.find((x) => x.id == objId));
        NotificationManager.success(msgSuccess);
        //handleRequestCloseCRU();
      }
    );
  };

  const removeItem = (list, itemId) => {
    let index = list.findIndex((x) => x.id === itemId);
    let data = [...list];
    data.splice(index, 1);
    return data;
  };
  const handleDelete = () => {
    callActionApi(
      () => deleteRadicadoOLF(currentRowObj.id),
      () => {
        const data = removeItem(radicadoOLFList, currentRowObj.id);
        setRadicadoOLFList(data);
        handleRequestCloseDel();
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const handleClickNoRadicado = () => setOpenDlgNoRadicado(true);
  const handleRequestCloseDlgNoRadicado = () => setOpenDlgNoRadicado(false);
  const handleNoRadicado = (comment) => {
    callApi(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.radicacionOLFNonEffective,
          comment,
        }),
      () => {
        handleRequestCloseDlgNoRadicado();
        handleRequestClose();
        dispatch(
          setPrescriptionState(PrescripStateEnum.radicacionOLFNonEffective)
        );
        dispatch(callReloadAlerts(true));
      }
    );
  };

  var readOnly = selectedPrescription.stateId == PrescripStateEnum.delivered;

  return (
    <div>
      <InfoCard
        data={{
          icon: "collection-text",
          title: IntlGetText("radicadoOLF.cardTitle"),
          subTitle: IntlGetText("radicadoOLF.cardSubTitle"),
          color: existRadicacionOLFInImpact(selectedPrescription.impactId, radicadoOLFList)
            || selectedPrescription.stateId == PrescripStateEnum.delivered ? "#20c997" : "#E53935",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="radicadoOLF.DialogTitle" />
        </DialogTitle>
        <DialogContent>
          {!readOnly &&
            !existRadicacionOLFInImpact(
              selectedPrescription.impactId,
              radicadoOLFList
            ) &&
            selectedPrescription.stateId !=
              PrescripStateEnum.radicacionOLFNonEffective && (
              <div className="col-lg-12 col-md-12 col-xs-12 text-right mb-4">
                <Tooltip
                  title={IntlGetText("radicadoOLF.NoRadicadoOLFButton")}
                  placement="bottom-end"
                >
                  <Button
                    onClick={handleClickNoRadicado}
                    variant="contained"
                    className="jr-btn bg-red text-white"
                  >
                    <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
                  </Button>
                </Tooltip>
                <DialogAlert
                  open={openDlgNoRadicado}
                  dialogTitle={<IntlMessages id="radicadoOLF.NoRadicadoOLF" />}
                  callBack={handleNoRadicado}
                  closeDialog={handleRequestCloseDlgNoRadicado}
                />
              </div>
            )}
          <Table
            data={radicadoOLFList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            readOnly={readOnly}
          />
          <Form
            rowObj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            authorizations={authorizations}
            handleCreateUpdate={handleCreateUpdate}
            radicadoOLFList={radicadoOLFList}
            setRadicadoOLFList={setRadicadoOLFList}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsuranceAuthorizationDialog;
