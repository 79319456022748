import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { Button, Grid } from "@material-ui/core";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import CardBox from "components/CardBox/index";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import Table, { defaultCol, dateColumn, getTextLabels } from "components/Form/Table";
import FormInput from "components/Form/Controls/input";
import { isRole } from "app/routes/psp/services/userService";
import { RoleEnum, PatientInstitutionStateList, PatientInstitutionStateEnum } from "constants/Enums";
import ModalConcent from "./modalConcent";
import DlgAddNewPatient from "./dlgAddNewPatient";
import NextFollowUpForm from "../details/modalForm/nextFollowUp";
import { callApiAsync, postIntitutionEndImpact } from "api/apiList";
import { NotificationManager } from "react-notifications";
import { DialogConfirmAsync } from "components/Form/Controls/DialogConfirm";
import {
  callApi,
  getIntitutionImpacts,
  postIntitutionActivatePatient,
  getInstitutions
} from "api/apiList";


const InstitutionHome = () => {
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { ciSignedDocument } = useSelector(({ institution }) => institution);
  const msgSuccess = IntlGetText("core.SuccessSave");

  const [state, setState] = useState({
    page: 0,
    count: 0,
    data: [],
    rowsPerPage: 10
  });
  const [openDlgAddPatient, setOpenDlgAddPatient] = useState(false);
  const [openDlgConfirmationModal, setOpenDlgConfirmationModal] = useState(false);
  const [institutionList, setInstitutionList] = useState([]);
  const [openDlgNextFollowUp, setOpenDlgNextFollowUp] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const isInstitutionManager = isRole(RoleEnum.institutionManager);

  const patientInstitutionStateList = PatientInstitutionStateList().filter(x => x.id > 1);

  const dlgNextFollowUpClose = () => {
    setCurrentRow(null);
    setOpenDlgNextFollowUp(false);
  };

  const dlgConfirmationCloseModal = () => {
    setOpenDlgConfirmationModal(false);
  };

  const defaultValues = {
    filterDateTypeIds: [],
    startDate: null,
    endDate: null,
    states: [],
    companyIds: [],
    search: ""
  };

  const validationSchema = {};

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors } = methods;

  const [filterTable, setFilterTable] = useState(defaultValues);

  const reloadTable = (page, sortOrder, filterTable, rowsPerPage) => {
    if (!isInstitutionManager && !ciSignedDocument)
      return;

    callApi(
      () => getIntitutionImpacts({ page, sortOrder, filterTable, rowsPerPage }),
      (d) => {
        setState({
          ...state,
          data: d.data,
          count: d.count,
          page: page,
          rowsPerPage: rowsPerPage
        });
      },
      fnSetLoader
    );
  };

  const loadTable = () => {
    reloadTable(state.page, undefined, filterTable, state.rowsPerPage);
  };

  useEffect(() => {
    if (!isInstitutionManager && !ciSignedDocument)
      return;
    loadTable();
    if (isInstitutionManager)
      callApi(getInstitutions, setInstitutionList);
  }, [ciSignedDocument]);

  const getOptions = ({ data, page, count, rowsPerPage }) => {
    return {
      filter: false,
      viewColumns: false,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [10, 50, 100],
      download: false,
      print: false,
      search: false,
      selectableRows: "none",

      serverSide: true,
      count: count,
      page: page,
      onTableChange: (action, tableState) => {
        if (action === "changePage" || action === "sort" || action === "changeRowsPerPage") {
          reloadTable(tableState.page, tableState.sortOrder, filterTable, tableState.rowsPerPage);
        }
      },
      textLabels: getTextLabels()
    };
  };

  const getObj = (tableMeta) =>
    state.data.find((x) => x.id === tableMeta.rowData[0]);

  let columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    }
  ];
  if (isInstitutionManager)
    columns.push(defaultCol("companyName", "institution.institution"));

  columns = columns.concat(
    [
      defaultCol("patient", "core.patientName"),
      dateColumn("impactDate", "core.patientDate"),
      dateColumn("planingDate", "core.scheduledAppointment"),
      defaultCol("state", "core.state"),
      {
        name: "actions",
        label: IntlGetText("core.actions"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var rowObj = getObj(tableMeta);
            return (
              <>
                <Button
                  component={Link}
                  to={{
                    pathname: "/app/institutionDetails",
                    impactRow: rowObj
                  }}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
                {!isInstitutionManager && rowObj && !rowObj.impactDate && rowObj.planingDate && !(rowObj && rowObj.stateId === PatientInstitutionStateEnum.finished) &&
                  <Button
                    onClick={() => {
                      setCurrentRow(rowObj);
                      setOpenDlgNextFollowUp(true);
                    }}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className="zmdi zmdi-edit"></i>
                  </Button>
                }
                {(rowObj && rowObj.stateId === PatientInstitutionStateEnum.finished) &&
                  <Button
                    onClick={() => {
                      setOpenDlgConfirmationModal(true);
                      setCurrentRow(rowObj);
                    }}
                    variant="contained"
                    className="jr-btn jr-btn-xs"
                  >
                    <i className="zmdi zmdi-edit"></i>
                  </Button>
                }
                <div className="row mt-3">
                </div>
              </>
            );
          },
        },
      },
    ]);

   const activatePatient = async (row) => {
    var obj = {
      patientId: row.patientId
     };
    await callApiAsync(
      () => postIntitutionActivatePatient(obj),
      (data) => {
        dlgConfirmationCloseModal();
        loadTable();
        NotificationManager.success(msgSuccess);
      },
      fnSetLoader
    );
    setCurrentRow(null);
  }

  const onFilter = handleSubmit((d) => {
    var obj = {
      filterDateTypeId: !d.filterDateTypeId ? null : Number(d.filterDateTypeId.value),
      startDate: !d.startDate
        ? null
        : dayjs(d.startDate).format("YYYY-MM-DDT00:00:00"),
      endDate: !d.endDate
        ? null
        : dayjs(d.endDate).format("YYYY-MM-DDT00:00:00"),
      states: !d.state ? [] : d.state.map((x) => Number(x.value)),
      companyIds: !d.institutionId ? [] : d.institutionId.map((x) => x.value),
      search: !d.search ? null : d.search
    };
    setFilterTable(obj);
    reloadTable(state.page, undefined, obj, state.rowsPerPage);
  });


  return (
    <div className="app-wrapper">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={4}>
                <FormSelectAutoComplete
                  name="filterDateTypeId"
                  label={<IntlMessages id="core.date" />}
                  options={[
                    { id: 1, name: IntlGetText('core.patientDate') },
                    { id: 2, name: IntlGetText('core.deliveryScheduledDate') }
                  ]}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormDatePicker
                  name="startDate"
                  label={<IntlMessages id="core.startDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  required={true}
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <FormDatePicker
                  name="endDate"
                  label={<IntlMessages id="core.endDate" />}
                  format="YYYY-MM-DD"
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <FormSelectAutoComplete
                  name="state"
                  label={<IntlMessages id="core.state" />}
                  options={patientInstitutionStateList}
                  margin="normal"
                  isMulti
                />
              </Grid>
              {isInstitutionManager &&
                <Grid item xs={12} md={3} lg={3}>
                  <FormSelectAutoComplete
                    name="institutionId"
                    label={<IntlMessages id="institution.institution" />}
                    options={institutionList}
                    margin="normal"
                    isMulti
                  />
                </Grid>
              }
              <Grid item xs={12} md={3} lg={3}>
                <FormInput
                  name="search"
                  label={<IntlMessages id="core.search" />}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button
                  onClick={onFilter}
                  className="mt-3"
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="core.filter" />
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </div>
      </div>
      <div className="row mt-3">
        {!isInstitutionManager && ciSignedDocument &&
          <div className="text-right mt-1 mb-1">
            <Button
              onClick={() => setOpenDlgAddPatient(true)}
              variant="contained"
              color="primary"
              className="ml-2"
            >
              {<IntlMessages id="core.add" />}
            </Button>
          </div>
        }
        <CardBox styleName="col-12" cardStyle="p-0">
          <Table
            className="border-dialog-card mt-2 mb-2"
            data={state.data}
            columns={columns}
            options={getOptions(state)}
          />
        </CardBox>
        <DlgAddNewPatient
          openDlg={openDlgAddPatient}
          closeDialog={() => setOpenDlgAddPatient(false)}
          callBack={() => {
            setOpenDlgAddPatient(false);
            loadTable();
          }}
        />
        {!isInstitutionManager && <ModalConcent />}

        <NextFollowUpForm
          openDlg={openDlgNextFollowUp}
          setOpenDlg={setOpenDlgNextFollowUp}
          closeDialog={dlgNextFollowUpClose}
          setImpactInfo={
            () => { //callback
              loadTable();
            }
          }
          followInfo={currentRow}
        />
        
        <DialogConfirmAsync
              openDialog={openDlgConfirmationModal}
              title={<IntlMessages id="core.Action" />}
              text={<IntlMessages id="institution.state" />}
              handleRequestClose={dlgConfirmationCloseModal}
              onAcceptClickAsync={async ()=>{
                await activatePatient(currentRow);
              }}
            />
      </div>
    </div>
  );
};

export default InstitutionHome;
