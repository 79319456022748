import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import FormulaForm from "./FormulaForm";
import {
  callActionApi,
  getAllStateProvince,
  getProductList,
} from "api/apiList";
import { callStartImpact } from "actions/Impact";
import { PrescripStateEnum } from "constants/Enums";

const FormulaDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, expeditionDateFormula, isCIRequired } = useSelector(
    ({ impact }) => impact
  );

  const [open, setOpen] = useState(false);

  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [productList, setProductList] = useState([]);

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentPath){
        NotificationManager.warning(msgConsentRequired);
        return;
    }

    setOpen(true);
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  const callApi = (apiFunc, setData) => {
    callActionApi(
      apiFunc,
      setData,
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  useEffect(() => {
    callApi(getAllStateProvince, setStateProvinceList);
    callApi(getProductList, setProductList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InfoCard
        data={{
          icon: "file-text",
          title: "Formula",
          subTitle: "Médica",
          color: (selectedPrescription.stateId == PrescripStateEnum.formulated ||
            selectedPrescription.stateId == PrescripStateEnum.formulatedWithErrors)
              ? "#E53935"
              : expeditionDateFormula == null
              ? "#E53935"
              : "#20c997",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog open={open} maxWidth="md" 
        disableBackdropClick={true} onClose={handleRequestClose}>
        <DialogTitle>
          <IntlMessages id="core.medicalFormula" />
        </DialogTitle>
        <DialogContent>
          <FormulaForm
            openDlg={open}
            selectedPrescription={selectedPrescription}
            stateProvinceList={stateProvinceList}
            productList={productList}
            handleRequestClose={handleRequestClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormulaDialog;
