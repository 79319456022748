import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import AlertNoRadicado from "./NoRadicado";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  getRadicadoInsuranceList,
  postCreateUpdateRadicadoInsurance,
  deleteRadicadoInsurance,
  postPrescriptionSetState,
} from "api/apiList";
import { PrescripStateEnum } from "constants/Enums";
import { setPrescriptionState } from "actions/Management";
import { callStartImpact } from "actions/Impact";

const existAuthorizationsInImpact = (impactId, radicadoList) =>
  radicadoList.find((x) => x.impactId == impactId) != undefined;

const InsuranceAuthorizationDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired } = useSelector(({ impact }) => impact);

  const [radicadoList, setRadicadoList] = useState([]);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});

  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentPath){
        NotificationManager.warning(msgConsentRequired);
        return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  useEffect(() => {
    callApi(
      () => getRadicadoInsuranceList(selectedPrescription.id),
      setRadicadoList
    );
  }, []);

  const handleCreateUpdate = async (data) => {
    await callApiAsync(
      () => postCreateUpdateRadicadoInsurance(data),
      (newData) => {
        setRadicadoList(newData);
        handleRequestCloseCRU();
        if (selectedPrescription.stateId != PrescripStateEnum.radicacionEPS) {
          dispatch(setPrescriptionState(PrescripStateEnum.radicacionEPS));
        }
      }
    );
  };

  const removeItem = (list, itemId) => {
    let index = list.findIndex((x) => x.id === itemId);
    let data = [...list];
    data.splice(index, 1);
    return data;
  };
  const handleDelete = () => {
    callApi(
      () => deleteRadicadoInsurance(currentRowObj.id),
      () => {
        const data = removeItem(radicadoList, currentRowObj.id);
        setRadicadoList(data);
        handleRequestCloseDel();
      }
    );
  };

  const handleClickContinue = () => {
    callApi(
      () =>
        postPrescriptionSetState({
          id: selectedPrescription.id,
          state: PrescripStateEnum.authorized,
        }),
      () => {
        dispatch(setPrescriptionState(PrescripStateEnum.authorized));
      }
    );
  };

  return (
    <div>
      <InfoCard
        data={{
          icon: "collection-text",
          title: IntlGetText("radicacionInsurance.Radicacion"),
          subTitle: IntlGetText("radicacionInsurance.Insurance"),
          color: ((selectedPrescription.stateId == PrescripStateEnum.noAuthorized || 
            selectedPrescription.stateId == PrescripStateEnum.radicacionEPSNonEffective||
            selectedPrescription.stateId == PrescripStateEnum.authorizationWithInconsistencies )
              && !existAuthorizationsInImpact(selectedPrescription.impactId, radicadoList))
            ? "#E53935"
            : !existAuthorizationsInImpact(selectedPrescription.impactId, radicadoList) ? "#E53935" : "#20c997",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="radicacionInsurance.DialogTitle" />
        </DialogTitle>
        <DialogContent>
          {!existAuthorizationsInImpact(
            selectedPrescription.impactId,
            radicadoList
          ) && <AlertNoRadicado />}          
          <Table
            data={radicadoList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            handleClickContinue={handleClickContinue}
            showBtnCotinue={ radicadoList.length > 0 &&
              !existAuthorizationsInImpact(selectedPrescription.impactId, radicadoList) 
            }
          />
          <Form
            radicado={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsuranceAuthorizationDialog;
