import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import Consent from "../Impact/Consent";
import FormulaDialog from "../Impact/Formula";
import ClinicalHistory from "../Impact/ClinicalHistory";
import PatientInfo from "../Impact/PatientInfo";
import Education from "../Impact/Education";
import RadicacionInsurance from "./RadicacionInsurance";
import InsuranceAuthorization from "./InsuranceAuthorization";
import RadicadoOLF from "./RadicadoOLF";
import Delivery from "./MedicineDelivery";
import Alert from "./Alert";
import Dosage from "./Dosage";
import ClinicalFinding from "./ClinicalFinding";
import MorisKySurvey from "./MorisKySurvey";
import MedicineConsumption from "./MedicineConsumption";
import MedicineConsumptionTracing from "./MedicineConsumptionTracing";
import EducationLevelSurvey from "./EducationLevelSurvey";
import HealingMaterial from "./HealingMaterial";
import CatheterStatus from "./CatheterStatus";
import InfusionPumps from "./InfusionPumps";
import ProductStatus from "./ProductStatus";
import HealthData from "./HealthData";
import AdditionalMedications from "../Impact/AdditionalMedications";
import PatientStateBox from "components/JaasModule/PatientStateBox";
import IsOnlyTracingBox from "components/JaasModule/IsOnlyTracingBox";
import {
  isOnlyTracing,
  isJaasModule,
  getBoolSetting,
  isTracingDeliveryBox,
  getUserInfo,
} from "app/routes/psp/services/userService";
import { loadInfoImpact } from "actions/Impact";
import {
  PrescripStateEnum,
  ProductBehavior,
  FolderType,
  RoleEnum,
  SettingsEnum,
  ShardKeyEnum,
} from "constants/Enums";
import { callActionApi, getInfoImpact } from "api/apiList";
import { isRole } from "app/routes/psp/services/userService";
import TracingDelivery from "./TracingDelivery";

const FirstImpact = () => {
  const dispatch = useDispatch();
  const { selectedPrescription, selectedMenuItem } = useSelector(
    ({ management }) => management
  );

  const { historyData } = useSelector(({ impact }) => impact);
  const isJaasModuleConfig = isJaasModule();
  const isTracingDeliveryBoxConfig = isTracingDeliveryBox();

  const isNurseProcedure = isRole(RoleEnum.nurse_procedure);

  useEffect(() => {
    callActionApi(
      () => getInfoImpact(selectedPrescription.impactId),
      (data) => {
        dispatch(loadInfoImpact(data));
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  }, []);

  const isOnlyTrace =
    isOnlyTracing() === true ||
    selectedPrescription.patient.isOnlyTracing === true ||
    !selectedPrescription.expeditionDate;
  const isPSPBoxesPhg = getBoolSetting(SettingsEnum.hideBoxes);
  const isOperando = getUserInfo().shardKey === ShardKeyEnum.operando;

  return (
    <div>
      <div className="row first-impact-box-margins">
        {(selectedMenuItem === FolderType.tracing ||
          selectedPrescription.stateId === PrescripStateEnum.fristImpact) && (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <Consent />
          </div>
        )}
        {!isNurseProcedure && (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <PatientInfo />
          </div>
        )}
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          {!(
            isOnlyTracing() === true ||
            selectedPrescription.patient.isOnlyTracing === true
          ) && <FormulaDialog />}
          {(isOnlyTracing() === true ||
            selectedPrescription.patient.isOnlyTracing === true) && (
            <HealthData />
          )}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <ClinicalHistory />
        </div>

        {selectedPrescription.stateId !== PrescripStateEnum.NewFormulation &&
          selectedMenuItem === FolderType.inProcess && (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Alert />
            </div>
          )}
        {(selectedMenuItem === FolderType.tracing ||
          selectedPrescription.stateId === PrescripStateEnum.fristImpact) && (
          <React.Fragment>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <AdditionalMedications />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Education />
            </div>
            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <NursePlan />
            </div> */}
          </React.Fragment>
        )}

        {(selectedPrescription.stateId === PrescripStateEnum.radicacionEPS ||
          selectedPrescription.stateId === PrescripStateEnum.noAuthorized ||
          selectedPrescription.stateId ===
            PrescripStateEnum.radicacionEPSNonEffective ||
          selectedPrescription.stateId ===
            PrescripStateEnum.authorizationWithInconsistencies) &&
          selectedMenuItem === FolderType.inProcess && (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <RadicacionInsurance />
            </div>
          )}
        {selectedPrescription.stateId == PrescripStateEnum.authorized &&
          selectedMenuItem === FolderType.inProcess && (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <InsuranceAuthorization />
            </div>
          )}
        {(selectedPrescription.stateId === PrescripStateEnum.radicacionOLF ||
          selectedPrescription.stateId ===
            PrescripStateEnum.radicacionOLFNonEffective ||
          selectedPrescription.stateId === PrescripStateEnum.delivered ||
          selectedPrescription.stateId === PrescripStateEnum.deliveryNotMade ||
          selectedPrescription.stateId ===
            PrescripStateEnum.incompleteDelivery) &&
          selectedMenuItem === FolderType.inProcess && (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <RadicadoOLF />
            </div>
          )}
        {(selectedPrescription.stateId === PrescripStateEnum.delivered ||
          selectedPrescription.stateId === PrescripStateEnum.deliveryNotMade ||
          selectedPrescription.stateId ===
            PrescripStateEnum.incompleteDelivery) &&
          selectedMenuItem === FolderType.inProcess && (
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <Delivery />
            </div>
          )}

        {selectedMenuItem === FolderType.tracing && (
          <>
            {!isOnlyTrace && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <Dosage />
              </div>
            )}
            {isJaasModuleConfig && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <PatientStateBox />
              </div>
            )}
            {!isTracingDeliveryBoxConfig && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <ClinicalFinding />
              </div>
            )}
            {!isOnlyTrace && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <MedicineConsumption />
              </div>
            )}
            {isOnlyTrace && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <MedicineConsumptionTracing />
              </div>
            )}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <ProductStatus />
            </div>
            {isTracingDeliveryBoxConfig && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <TracingDelivery />
              </div>
            )}
          </>
        )}
        {((!isOnlyTrace &&
          selectedMenuItem == FolderType.tracing &&
          selectedPrescription.customProductBehaviorId ===
            ProductBehavior.iclusig) ||
          (selectedMenuItem == FolderType.tracing &&
            selectedPrescription.customProductBehaviorId ===
              ProductBehavior.dacepton)) && (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <MorisKySurvey />
          </div>
        )}
        {((!isOnlyTrace &&
          selectedMenuItem === FolderType.tracing &&
          selectedPrescription.customProductBehaviorId ===
            ProductBehavior.remodulin) ||
          (selectedMenuItem === FolderType.tracing &&
            selectedPrescription.customProductBehaviorId ===
              ProductBehavior.dacepton)) && (
          <React.Fragment>
            {!isPSPBoxesPhg && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <CatheterStatus
                  isRemodulin={
                    selectedPrescription.customProductBehaviorId ===
                    ProductBehavior.remodulin
                  }
                />
              </div>
            )}
            {!isPSPBoxesPhg && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <InfusionPumps />
              </div>
            )}
            {selectedPrescription.customProductBehaviorId ===
              ProductBehavior.remodulin && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <EducationLevelSurvey />
              </div>
            )}
            {!isPSPBoxesPhg && (
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <HealingMaterial />
              </div>
            )}
          </React.Fragment>
        )}
        {isJaasModuleConfig && (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <IsOnlyTracingBox />
          </div>
        )}
        {isOperando && (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <InfusionPumps />
          </div>
        )}
      </div>
    </div>
  );
};

export default FirstImpact;
