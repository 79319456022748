import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import {
  isOnlyTracing,
  isJaasModule,
} from "app/routes/psp/services/userService";
import { menuItems } from "./data";
import {
  setSelectedProductLine,
  setSelectedPrescriptionState,
  loadPrescriptions,
  setLoader,
  showErrorMessage,
  setSelectedPrescription,
  updateManageSearch,
} from "actions/Management";
import { callApi, getPrescriptions } from "../../../../api/apiList";
import { FolderType, RoleEnum } from "constants/Enums";
import { isRole } from "app/routes/psp/services/userService";

const NavLabels = ({ menuItem, labels, handler }) => {
  const dispatch = useDispatch();
  const [selectedLabelID, setSelectedLabelID] = useState(0);
  const labelByType =
    menuItem !== undefined && labels
      ? labels.filter((x) => x.type === menuItem)
      : labels;

  return !labelByType
    ? ""
    : labelByType.map((label, index) => {
        return (
          <li
            key={index}
            onClick={() => {
              dispatch(handler(label.id));
              setSelectedLabelID(label.id === selectedLabelID ? 0 : label.id);
              setTimeout(() => {
                dispatch(setLoader(false));
              }, 1500);
            }}
          >
            <span
              className={`jr-link ${
                label.id === selectedLabelID ? "active" : ""
              }`}
            >
              <i className={`zmdi zmdi-label-alt text-${label.color}`} />
              <span className="text-uppercase">{label.title}</span>
            </span>
          </li>
        );
      });
};

const getInProcessStates = (folderId, stateList) => {
  return folderId == FolderType.inProcess
    ? stateList.filter((x) => !x.isImpactType)
    : stateList;
};

const SideBar = () => {
  const [sidebarState, setSidebarState] = useState(true);
  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { selectedMenuItem, productLines, prescriptionStates } = useSelector(
    ({ management }) => management
  );
  const { width } = useSelector(({ settings }) => settings);
  const isNurseProcedure = isRole(RoleEnum.nurse_procedure);

  const resetFilters = () => {
    dispatch(setSelectedPrescriptionState(-1));
    dispatch(setSelectedProductLine(-1));
    dispatch(updateManageSearch(""));
  };

  const onSelectItem = (itemId, isMount) => {
    if(isNurseProcedure){itemId = 1}
    setSidebarState(false);
    callApi(
      () => getPrescriptions(itemId),
      (data) => {
        dispatch(loadPrescriptions(data, itemId));
        if (!isMount) {
          dispatch(setSelectedPrescription(null));
        }
        setSidebarState(true);
        resetFilters();
      },
      fnSetLoader
    );
  };

  useEffect(() => {
    onSelectItem(selectedMenuItem, true);
  }, []);

  const handleGetMenuItems = () => {
    var menu = isOnlyTracing()
      ? menuItems.filter((x) => x.id !== 1 && x.id !== 3)
      : menuItems;
    menu = isNurseProcedure ? menuItems.filter((x) => x.id == 1) : menuItems;

    return menu.map((item, index) => (
      <li
        className={sidebarState ? "sideBar" : "sidebarDisable"}
        key={index}
        onClick={() => onSelectItem(item.id)}
      >
        <span
          className={`jr-link ${selectedMenuItem === item.id ? "active" : ""}`}
        >
          <i className={`zmdi zmdi-${item.icon}`} />
          <span>{item.title}</span>
        </span>
      </li>
    ));
  };

  return (
    <div className="module-side">
      <div className="module-side-header">
        <div className="module-logo">
          <i className="zmdi zmdi-settings mr-4" />
          <span>
            <IntlMessages id="core.management" />
          </span>
        </div>
      </div>

      <div className="module-side-content">
        <CustomScrollbars
          className="module-side-scroll scrollbar"
          style={{
            height:
              width >= 1200 ? "calc(100vh - 200px)" : "calc(100vh - 80px)",
          }}
        >
          <ul className="module-nav">
            {handleGetMenuItems()}
            {!isNurseProcedure && (
              <li className="module-nav-label">
                <IntlMessages id="core.productLine" />
              </li>
            )}
            {!isNurseProcedure && (
              <NavLabels
                labels={productLines}
                handler={setSelectedProductLine}
              />
            )}
            {!isNurseProcedure && (
              <li className="module-nav-label">
                <IntlMessages
                  id={
                    selectedMenuItem === FolderType.tracing
                      ? "core.impacts"
                      : selectedMenuItem === FolderType.adverseEvents
                      ? "core.type"
                      : "core.states"
                  }
                />
              </li>
            )}
            {!isNurseProcedure && (
              <NavLabels
                menuItem={selectedMenuItem}
                labels={getInProcessStates(
                  selectedMenuItem,
                  prescriptionStates
                )}
                handler={setSelectedPrescriptionState}
              />
            )}
          </ul>
        </CustomScrollbars>
      </div>
    </div>
  );
};

export default SideBar;
