import React from "react";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { Button } from "@material-ui/core";
import Table, {
  minimalOptions,
  defaultCol,
  dateColumn,
} from "components/Form/Table";
import CardBox from "components/CardBox/index";

const TableAM = ({
  data,
  handleOpenCRU,
  handleOpenDel,
  setCurrentRowObj,
  currentImpact,
  readOnly
}) => {
  const handleClick = (tableMeta) => {
    handleOpenCRU();
    setCurrentRowObj(
      !tableMeta ? null : tableMeta.tableData[tableMeta.rowIndex]
    );
  };
  const handleClickDelete = (tableMeta) => {
    handleOpenDel();
    setCurrentRowObj(tableMeta.tableData[tableMeta.rowIndex]);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    dateColumn("impactDate", "core.impactDate", "YYYY/MM/DD"),
    defaultCol("batch", "medicineDelivery.lotNumber"),
    defaultCol("consumption", "core.amount"),
    {
      name: "actions",
      label: IntlGetText("core.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          var rowObj = tableMeta.tableData[tableMeta.rowIndex];
          if (rowObj.impactId != currentImpact || readOnly) {
            return (
              <div>
                <Button
                  onClick={() => handleClick(tableMeta)}
                  variant="contained"
                  className="jr-btn jr-btn-xs"
                >
                  <i className="zmdi zmdi-eye"></i>
                </Button>
              </div>
            );
          }

          return (
            <div>
              <Button
                onClick={() => handleClick(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-edit"></i>
              </Button>
              <Button
                onClick={() => handleClickDelete(tableMeta)}
                variant="contained"
                className="jr-btn jr-btn-xs"
              >
                <i className="zmdi zmdi-delete"></i>
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="row">
      <CardBox styleName="col-12" cardStyle="p-0 border-dialog-card">
        <Table
          className="border-dialog-card"
          data={data}
          columns={columns}
          options={minimalOptions()}
        />
      </CardBox>
      {!readOnly && (
        <div className="col-lg-12 col-md-12 col-xs-12 mt-4 text-center">
          <Button
            onClick={() => handleClick(null)}
            variant="contained"
            color="primary"
          >
            {<IntlMessages id="core.add" />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableAM;
