import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";
import FormDatePicker from "components/Form/Controls/datepicker";
import FormSelectAutoComplete from "components/Form/Controls/select-autocomplete";
import dayjs from "dayjs";

const FormCreateUpdate = (props) => {
  const {
    openCRU,
    handleRequestClose,
    obj,
    handleCreateUpdate,
    cityList,
    oplList,
    prescriptionId,
    currentObjEdit,
  } = props;

  const defaultValues = {
    estimatedDeliveryDate: null,
    deliveryDate: null,
    marketchainId: null,
    cityId: null,
    comment: "",
  };

  const validationSchema = {
    cityId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    marketchainId: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
      estimatedDeliveryDate: yup.date()
      .nullable()
      .typeError(IntlGetText("yup.InvalidDate"))
      .required(<IntlMessages id="core.fieldRequired" />),
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;

  const getCboValue = (items, id) => {
    if (typeof id == "object") {
      return id;
    }
    const result = items.find((x) => x.id === id);
    return result ? { label: result.name, value: result.id } : "";
  };


  const loadData = () => {
    setValue(
      "estimatedDeliveryDate",
      !obj || !obj.estimatedDeliveryDate
        ? null
        : dayjs(obj.estimatedDeliveryDate)
    );
    setValue(
      "deliveryDate",
      !obj || !obj.deliveryDate ? null : dayjs(obj.deliveryDate)
    );
    setValue(
      "marketchainId",
      !obj || !obj.marketChainId ? "" : getCboValue(oplList, obj.marketChainId)
    );
    setValue(
      "cityId",
      !obj || !obj.cityId ? "" : getCboValue(cityList, obj.cityId)
    );
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
  };

  useEffect(() => {
    if (openCRU) {
      setTimeout(loadData, 150);
    }
  }, [openCRU]);

  const onSubmit = async (d, e) => {
    var item = {
      id: !obj || !obj.id ? 0 : obj.id,
      prescriptionId: prescriptionId,
      estimatedDeliveryDate: d.estimatedDeliveryDate,
      deliveryDate: d.deliveryDate,
      marketChainId: parseInt(d.marketchainId.value),
      cityId: parseInt(d.cityId.value),
      comment: !d.comment ? null : d.comment,
    };
    await handleCreateUpdate(item);
  };

  const allowEdit = () => {
    if (!obj || !obj.id) return true;
  };

  const disabled = !allowEdit();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : currentObjEdit ? "core.edit":"sidebar.view"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="estimatedDeliveryDate"
                    label={
                      <IntlMessages id="deliveryTracing.estimatedDeliveryDate" />
                    }
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormDatePicker
                    name="deliveryDate"
                    label={<IntlMessages id="deliveryTracing.deliveryDate" />}
                    format="YYYY-MM-DD"
                    margin="normal"
                    disableFuture={false}
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="cityId"
                    label={<IntlMessages id="core.city" />}
                    options={cityList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FormSelectAutoComplete
                    name="marketchainId"
                    label={<IntlMessages id="deliveryTracing.opl" />}
                    options={oplList}
                    margin="normal"
                    disabled={!currentObjEdit}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    disabled={!currentObjEdit}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {currentObjEdit && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestClose} color="secondary">
          <IntlMessages id="core.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FormCreateUpdate;
