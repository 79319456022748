import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import InfoCard from "components/InfoCard";
import DialogAlert from "components/Form/DialogAlert";
import Form from "./Form";
import Table from "./Table";
import {
  callApi,
  callApiAsync,
  callActionApi,
  getRadicadoOLFList,
  getMedicineDeliveryList,
  postCreateUpdateMedicineDelivery,
  deleteMedicineDelivery,
  postGenerateAlert
} from "api/apiList";
import { setPrescriptionState } from "actions/Management";
import { callStartImpact,callReloadAlerts } from "actions/Impact";
import { PrescripStateEnum, RadicadoLogisticOperatorState } from "constants/Enums";

const existDeliveriesInImpact = (impactId, deliveries) =>
  deliveries.find((x) => x.impactId == impactId) != undefined;

const MedicineDeliveryDialog = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired } = useSelector(({ impact }) => impact);

  const [radicadoOLFList, setRadicadoOLFList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDlgNoRadicado, setOpenDlgNoRadicado] = useState(false);
  const [currentRowObj, setCurrentRowObj] = useState({});

  const msgSuccess = IntlGetText("core.SuccessSave");
  const msgStartImpactRequired = IntlGetText("impact.startImpactRequired");
  const msgConsentRequired = IntlGetText("impact.consentRequired");

  const handleClickOpen = () => {
    if(!impactStartDate){
      dispatch(callStartImpact(true));
      return;
    }
    if(isCIRequired && !consentPath){
        NotificationManager.warning(msgConsentRequired);
        return;
    }
    setOpen(true);
  };
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => {
    callApi(
      () => getRadicadoOLFList(selectedPrescription.id),
      (data) => {
        setRadicadoOLFList(data);
        setOpenCRU(true);
      }
    );
  };
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);

  useEffect(() => {
    callApi(
      () => getMedicineDeliveryList(selectedPrescription.id),
      (data) => {
        setDeliveryList(data.deliveriesList);
      }
    );
  }, []);

  const generateIncompleteDeliveryAlert = (comment) => {
    callApi(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.incompleteDelivery,
          comment,
        }),
      () => {
        dispatch(setPrescriptionState(PrescripStateEnum.incompleteDelivery));
        dispatch(callReloadAlerts(true));
      }
    );
  };
  const handleCreateUpdate = async (data) => {
    var isNew = data.id == 0;
    await callApiAsync(
      () => postCreateUpdateMedicineDelivery(data),
      (response) => {
        setDeliveryList(response.deliveriesList);
        if (response.formulaAmount == response.totalDeliveries) {
        } else if (
          isNew &&
          selectedPrescription.stateId == PrescripStateEnum.deliveryNotMade
        ) {
          dispatch(setPrescriptionState(PrescripStateEnum.delivered));
        }
        
        if(data.commentAlert)
          generateIncompleteDeliveryAlert(data.commentAlert);
        //if(isNew){
        var objId = data.id > 0 ? data.id : Math.max(...response.deliveriesList.map(x=>x.id));
        setCurrentRowObj(response.deliveriesList.find(x=>x.id == objId));
        //}
        NotificationManager.success(msgSuccess);
      }
    );
  };

  const removeItem = (list, itemId) => {
    let index = list.findIndex((x) => x.id === itemId);
    let data = [...list];
    data.splice(index, 1);
    return data;
  };
  const handleDelete = () => {
    callActionApi(
      () => deleteMedicineDelivery(currentRowObj.id),
      () => {
        const data = removeItem(deliveryList, currentRowObj.id);
        setDeliveryList(data);
        handleRequestCloseDel();
      },
      (error) => {
        if (typeof error === "string") NotificationManager.error(error);
      } //, (data) => dispatch(setLoader(data))
    );
  };

  const handleClickNoRadicado = () => setOpenDlgNoRadicado(true);
  const handleRequestCloseDlgNoRadicado = () => setOpenDlgNoRadicado(false);
  const handleNoRadicado = (comment) => {
    callApi(
      () =>
        postGenerateAlert({
          prescriptionId: selectedPrescription.id,
          prescriptionState: PrescripStateEnum.deliveryNotMade,
          comment,
        }),
      () => {
        handleRequestCloseDlgNoRadicado();
        handleRequestClose();
        dispatch(setPrescriptionState(PrescripStateEnum.deliveryNotMade));
        dispatch(callReloadAlerts(true));
      }
    );
  };

  var readOnly = selectedPrescription.stateId == PrescripStateEnum.incompleteDelivery;

  return (
    <div>
      <InfoCard
        data={{
          icon: "hospital",
          title: IntlGetText("medicineDelivery.cardTitle"),
          subTitle: IntlGetText("medicineDelivery.cardSubTitle"),
          color: existDeliveriesInImpact(selectedPrescription.impactId, deliveryList) ? "#20c997" : "#E53935",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="medicineDelivery.DialogTitle" />
        </DialogTitle>
        <DialogContent>
          { !readOnly && !existDeliveriesInImpact(
            selectedPrescription.impactId,
            deliveryList
          ) && (
            <div className="col-lg-12 col-md-12 col-xs-12 text-right mb-4">
              <Tooltip title={IntlGetText("medicineDelivery.deliveryNotMade")}>
                <Button
                  onClick={handleClickNoRadicado}
                  variant="contained"
                  className="jr-btn bg-red text-white"
                >
                  <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
                </Button>
              </Tooltip>
              <DialogAlert
                open={openDlgNoRadicado}
                dialogTitle={
                  <IntlMessages id="medicineDelivery.alertDeliveryNotMade" />
                }
                callBack={handleNoRadicado}
                closeDialog={handleRequestCloseDlgNoRadicado}
              />
            </div>
          )}
          <Table
            data={deliveryList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            readOnly={readOnly}
          />
          <Form
            rowObj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            radicadoOLFList={radicadoOLFList.filter(x=>(!x.hasDeliveries && x.state != RadicadoLogisticOperatorState.deliveryNotMade)
              || ( currentRowObj && x.id == currentRowObj.radicadoLogisticOperatorId) )}
            handleCreateUpdate={handleCreateUpdate}
            deliveryList={deliveryList}
            setDeliveryList={setDeliveryList}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MedicineDeliveryDialog;
