import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const FormCreateUpdate = (props) => {
  const { 
    openCRU, 
    handleRequestClose, 
    impactId, 
    obj, 
    handleCreateUpdate, 
    medicineQuantity, 
    amountConsumed,
    readOnly
  } = props;

  const getConsumptionMax = () => {
    if(!medicineQuantity) return 0;

    return medicineQuantity - amountConsumed;
  };

  const mgsMax = IntlGetText("medicineDelivery.consumptionValidation");

  const defaultValues = {    
    batch : null,
    consumption: "",
    comment : ""
  };
  const validationSchema = { 
    consumption:  yup.number()
      .typeError(IntlGetText("yup.numericValidation"))
      .min(1, IntlGetText("yup.numericValidation"))
      .required(<IntlMessages id="core.fieldRequired" />)
      .max(getConsumptionMax(), IntlGetText("consumptionTracing.consumptionValidation")+" ${max}")
      .nullable()
  };

  const methods = useForm({ 
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, watch, setValue, errors } = methods;
  
  const consumptionValue = watch("consumption");

  const loadData = () => {
    setValue("batch", !obj || !obj.batch ? "" : obj.batch);
    setValue("consumption", !obj || !obj.consumption ? "" : obj.consumption);
    setValue("comment", !obj || !obj.comment ? "" : obj.comment);
  };

  useEffect(()=>{
    if(openCRU){
      setTimeout(loadData,150);
    }
  },[openCRU]);

  const onSubmit = async (d, e) => {
    const max = getConsumptionMax();
    if(Number(d.consumption) > max){
      var msg = mgsMax + max;
      NotificationManager.warning(msg);
      return;
    }

    var item = {
      id: !obj || !obj.id ? 0 : obj.id,
      impactId: impactId,
      batch : !d.batch ? null : d.batch,
      consumption: Number(d.consumption),
      comment : !d.comment ? null : d.comment,
    };
    await handleCreateUpdate(item);
  };

  const allowEdit = () => {
    if (!obj || !obj.id) return true;

    if (readOnly) return false;

    return obj.impactId == impactId;
  };
  const disabled = !allowEdit();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={openCRU}
      disableBackdropClick={true}
      onClose={handleRequestClose}
    >
      <DialogTitle>
        <IntlMessages id={!obj ? "core.add" : "core.edit"} />
      </DialogTitle>
      <DialogContent>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <FormProvider {...methods}>
            <form className="row" noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <FormInput
                    name="batch"
                    label={<IntlMessages id="medicineDelivery.lotNumber" />}
                    margin="normal"
                    disabled={disabled}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormInput
                    name="consumption"
                    label={<IntlMessages id="core.amount" />}
                    margin="normal"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormInput
                    name="comment"
                    label={<IntlMessages id="core.comment" />}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    disabled={disabled}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          { !disabled &&
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          }
        </div>  
      </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>  
  );
};
export default FormCreateUpdate;
