import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import InfoCard from "components/InfoCard/index";
import Form from "./Form";
import Table from "./Table";
import Indicator from "./indicator.js";
import {
  callApi,
  callApiAsync,
  getMedicineConsumptionTracingList,
  postCreateUpdateConsumptionTracing,
  deleteConsumptionTracing,
  getGetIndicatorsTracing,
} from "api/apiList";
import { callStartImpact, callReloadConsumptionTracing } from "actions/Impact";

const existItemsInImpact = (impactId, dataList) =>
  dataList.find((x) => x.impactId == impactId) != undefined;

const getAmountConsumed = (consumptionList, currentRowObj) => {
  let data = [...consumptionList];
  if(currentRowObj)
    data = data.filter(x=>x.id != currentRowObj.id)

  return data.length === 0 ? 0 : data.map(x=>x.consumption).reduce((prev, curr) => prev + curr, 0);
}

const MedicineConsumptionTracing = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);
  const { impactStartDate, consentPath, isCIRequired, medicineQuantity, reloadConsumptionTracing } = useSelector(
    ({ impact }) => impact
  );
  
  const [open, setOpen] = useState(false);
  const [openCRU, setOpenCRU] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleRequestClose = () => setOpen(false);
  const handleOpenCRU = () => setOpenCRU(true);
  const handleRequestCloseCRU = () => setOpenCRU(false);
  const handleRequestCloseDel = () => setOpenDeleteDialog(false);
  const [consumptionList, setConsumptionList] = useState([]);
  const [indicatorInfo, setIndicatorInfo] = useState(null);
  const [currentRowObj, setCurrentRowObj] = useState(null);
  
  const msgConsentRequired = IntlGetText("impact.consentRequired");
  const msgInvalidAddOperation = IntlGetText(
    "medicineDelivery.invalidAddOperation"
  );

  const handleClickOpen = () => {
    if (!impactStartDate) {
      dispatch(callStartImpact(true));
      return;
    }
    if (isCIRequired && !consentPath) {
      NotificationManager.warning(msgConsentRequired);
      return;
    }
    setOpen(true);
  };
  
  const loadData = () => {
    callApi(
      () => getMedicineConsumptionTracingList(selectedPrescription.id),
      setConsumptionList
    );
    callApi(() => getGetIndicatorsTracing(selectedPrescription.id), setIndicatorInfo);
  };
  
  useEffect(() => loadData(), []);

  useEffect(() => {
    if (reloadConsumptionTracing) {
      loadData();
      dispatch(callReloadConsumptionTracing(false));
    }
  }, [reloadConsumptionTracing]);
  
  const existMedicineInImpact = (medicineConsumption) => {
    return (
      consumptionList.find(
        (x) =>
          x.impactId == selectedPrescription.impactId &&
          x.medicineDeliveryDetailId ===
            medicineConsumption.medicineDeliveryDetailId &&
          x.id !== medicineConsumption.id
      ) !== undefined
    );
  };
  
  const handleCreateUpdate = async (data) => {
    if (existMedicineInImpact(data)) {
      NotificationManager.warning(msgInvalidAddOperation);
      return;
    }
    await callApiAsync(
      () => postCreateUpdateConsumptionTracing(data),
      () => {
        handleRequestCloseCRU();
        loadData();
      }
    );
  };

  const handleDelete = (data) => {
    callApi(
      () => deleteConsumptionTracing(currentRowObj.id),
      () => {
        handleRequestCloseDel();
        setCurrentRowObj(null);
        loadData();
      }
    );
  };

  var readOnly = false;

  return (
    <>
      <InfoCard
        data={{
          icon: "hospital",
          title: IntlGetText("medicineConsumption.cardTitle"),
          subTitle: IntlGetText("medicineConsumption.cardSubTitle"),
          color: existItemsInImpact(
            selectedPrescription.impactId,
            consumptionList
          )
            ? "#20c997"
            : "#757575",
        }}
        onClickBox={handleClickOpen}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id="medicineDelivery.dlgTitle" />
        </DialogTitle>
        <DialogContent>
          <Indicator indicatorInfo={indicatorInfo}/>
          <Table
            data={consumptionList}
            handleOpenCRU={handleOpenCRU}
            handleOpenDel={() => setOpenDeleteDialog(true)}
            setCurrentRowObj={setCurrentRowObj}
            currentImpact={selectedPrescription.impactId}
            readOnly={readOnly}
          />
          <Form
            obj={currentRowObj}
            impactId={selectedPrescription.impactId}
            openCRU={openCRU}
            handleRequestClose={handleRequestCloseCRU}
            handleCreateUpdate={handleCreateUpdate}
            medicineQuantity = {medicineQuantity}
            amountConsumed = {getAmountConsumed(consumptionList, currentRowObj)}
            readOnly={readOnly}
          />
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDeleteDialog}
            disableBackdropClick={true}
            onClose={handleRequestCloseDel}
          >
            <DialogTitle>
              <IntlMessages id="additionalMedications.MsgDelConfirmation" />
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleRequestCloseDel} color="secondary">
                <IntlMessages id="core.cancel" />
              </Button>
              <Button onClick={handleDelete} color="primary">
                <IntlMessages id="core.accept" />
              </Button>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRequestClose} color="secondary">
            <IntlMessages id="core.close" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MedicineConsumptionTracing;