import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "actions/Base";
import { useForm, FormProvider } from "react-hook-form";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import AppLocale from "lngProvider";
import { Button, Grid } from "@material-ui/core";
import FormSelectAutoComplete, {
  getCboValue,
} from "components/Form/Controls/select-autocomplete";
import { NotificationManager } from "react-notifications";
import CustomChart from "components/CustomChart";
import { isRole } from "app/routes/psp/services/userService";
import { RoleEnum } from "constants/Enums";
import {
  totalChart,
  pieChart,
  columnChart,
  QuestionChart,
  MultiLineChart,
  MultiMonthKPI,
  MultiColumnChart,
} from "./optionsCharts";

import {
  callApi,
  callApiAsync,
  getRepList,
  getDashboardOperative,
} from "api/apiList";

const getYears = () => {
  var max = dayjs().get("year");
  var min = 2022;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years.map((x) => ({ id: x, name: x }));
};

const getMonths = () => {
  return [
    { id: 1, name: "Enero" },
    { id: 2, name: "Febrero" },
    { id: 3, name: "Marzo" },
    { id: 4, name: "Abril" },
    { id: 5, name: "Mayo" },
    { id: 6, name: "Junio" },
    { id: 7, name: "Julio" },
    { id: 8, name: "Agosto" },
    { id: 9, name: "Septiembre" },
    { id: 10, name: "Octubre" },
    { id: 11, name: "Noviembre" },
    { id: 12, name: "Diciembre" },
  ];
};

const getPropArray = (obj, prop) => {
  if (!obj) return [];
  return obj[prop];
};

const DashboardOperative = () => {
  const msgDoctorRequired = IntlGetText("dashboarPA.doctorRequired");

  const dispatch = useDispatch();
  const fnSetLoader = (data) => dispatch(setLoader(data));
  const { locale } = useSelector(({ settings }) => settings);
  const currentAppLocale = AppLocale[locale.locale];
  const currentLocale = currentAppLocale.locale;

  const [totalInfo, setTotalInfo] = useState(null);
  const [repList, setRepList] = useState([]);
  const [prescriptionList, setPrescriptionList] = useState([]);

  const yearList = getYears();
  const monthList = getMonths();

  const defaultValues = {
    year: null,
    month: null,
  };
  const validationSchema = {
    year: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
    month: yup
      .object()
      .shape({ value: yup.string().required() })
      .required(<IntlMessages id="core.fieldRequired" />)
      .nullable(),
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchema)),
  });
  const { handleSubmit, errors, setValue } = methods;

  useEffect(() => {
    if (!isRole(RoleEnum.rep)) callApi(getRepList, setRepList);
    //onFilter();
  }, []);

  const onFilter = handleSubmit(async (d) => {
    setValue("doctors", "");
    var obj = {
      repId: !d.repId ? null : d.repId.value,
      year: !d.year ? null : Number(d.year.value),
      month: !d.month ? null : Number(d.month.value),
      doctorId: null
    };

    await callApiAsync(
      () => getDashboardOperative(obj),
      (data) => {
        setPrescriptionList(data.chartPrescriptionByYear);
        setTotalInfo(data);
        console.log(data.chartPrescriptionByYear)
      },
      fnSetLoader
    );
  });

  const onFilterDoctor = handleSubmit(async (d) => {
    var obj = {
      doctorId: d.doctors ? d.doctors.value : null,
      year: !d.year ? null : Number(d.year.value),
      month: !d.month ? null : Number(d.month.value),
      repId: !d.repId ? null : d.repId.value,
    };
    
    await callApiAsync(
      () => getDashboardOperative(obj),
      (data) => {
        setPrescriptionList(data.chartPrescriptionByYear);
        setTotalInfo(data);
      },
      fnSetLoader
    );
  });
  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
      <FormProvider {...methods}>
        <Grid container spacing={1}>
          {!isRole(RoleEnum.rep) && (
            <Grid item xs={12} md={4} lg={4}>
              <FormSelectAutoComplete
                name="repId"
                label={<IntlMessages id="role.visitor" />}
                options={repList}
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="year"
              label={<IntlMessages id="core.year" />}
              options={yearList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormSelectAutoComplete
              name="month"
              label={<IntlMessages id="calendar.messages.month" />}
              options={monthList}
              margin="normal"
              required={true}
              errorobj={errors}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Button
              onClick={onFilter}
              className="mt-3"
              variant="contained"
              color="primary"
            >
              <IntlMessages id="core.filter" />
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={totalChart(
              "dashboarPA.chartByStateTitle",
              "core.state",
              currentLocale,
              getPropArray(totalInfo, "chartByState")
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartByGenderTitle",
              "core.gender",
              currentLocale,
              getPropArray(totalInfo, "chartByGender")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartByPatologyTitle",
              "patientPA.pathology",
              currentLocale,
              getPropArray(totalInfo, "chartByPatology")
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartByContact",
              "core.Contact",
              currentLocale,
              getPropArray(totalInfo, "chartByContact")
            )}
          />
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByRepTitle",
              "role.visitor",
              currentLocale,
              getPropArray(totalInfo, "chartByRep")
            )}
          />
        </div>
      </div> */}
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={QuestionChart(
              "dashboarPA.chartByRepTitle",
              currentLocale,
              getPropArray(totalInfo, "chartByRep")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={pieChart(
              "dashboarPA.chartTreatmentState",
              "patientPA.treatment",
              currentLocale,
              getPropArray(totalInfo, "chartByTreatmentState")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByInsuranceTitle",
              "core.insurance",
              currentLocale,
              getPropArray(totalInfo, "chartByInsurance")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByCityTitle",
              "core.city",
              currentLocale,
              getPropArray(totalInfo, "chartByCity")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartByDoctorTitle",
              "core.doctor",
              currentLocale,
              getPropArray(totalInfo, "chartByDoctor")
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={MultiLineChart(
              "dashboarPA.chartTrend",
              getPropArray(totalInfo, "chartByYear"),
              currentLocale
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={columnChart(
              "dashboarPA.chartDoctorsByMonthTitle",
              "dashboarPA.categories",
              currentLocale,
              getPropArray(totalInfo, "chartDoctorByMonth"),
              "core.doctors"
            )}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-12">
          <CustomChart
            options={MultiColumnChart( "dashboarPA.chartDoctorsByTryMonthTitle", getPropArray(totalInfo, "getChartDoctorByTrimester") )}
          />
        </div>
      </div>
      <div style={{ background: "white" }}>
        <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
          <FormProvider {...methods}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={4}>
                <FormSelectAutoComplete
                  name="doctors"
                  label={<IntlMessages id="psp.menu.doctors" />}
                  options={getPropArray(totalInfo, "doctorList")}
                  margin="normal"
                  errorobj={errors}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Button
                  onClick={onFilterDoctor}
                  className="mt-3"
                  variant="contained"
                  color="primary"
                >
                  <IntlMessages id="core.filter" />
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </div>
        <div className="row mt-3">
          <div className="col-xl-12 col-lg-12 col-12">
            <CustomChart
              options={columnChart(
                "dashboarPA.chartTrendPrescription",
                "dashboarPA.months",
                currentLocale,
                getPropArray(totalInfo, "chartPrescriptionByYear"),
                "core.prescriptions"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardOperative;
//
