import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { isJaasModule } from "app/routes/psp/services/userService";
import IsOnlyTracingBox from "components/JaasModule/IsOnlyTracingBox";
import ConsentDialog from "./Consent";
import FormulaDialog from "./Formula";
import ClinicalHistory from "./ClinicalHistory";
import PatientInfo from "./PatientInfo";
import Education from "./Education";
import NursePlan from "./NursePlan/index";
import AdditionalMedications from "./AdditionalMedications";
import { loadInfoImpact } from "actions/Impact";
import { callActionApi, getInfoImpact } from "api/apiList";

const FirstImpact = () => {
  const dispatch = useDispatch();
  const { selectedPrescription } = useSelector(({ management }) => management);

  const isJaasModuleConfig = isJaasModule();
  
  useEffect(() => {
      callActionApi(
          ()=>getInfoImpact(selectedPrescription.impactId), 
          (data)=>dispatch(loadInfoImpact(data)),
          (error) => {
              if(typeof(error) === "string")
                  NotificationManager.error(error);
          }//, (data) => dispatch(setLoader(data))
      );
    }, []);

  return (
    <div>
      <div className="row first-impact-box-margins">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <ConsentDialog />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <PatientInfo />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <FormulaDialog />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <ClinicalHistory />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <NursePlan />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <Education />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <AdditionalMedications/>
        </div>
        {isJaasModuleConfig &&
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <IsOnlyTracingBox/>
          </div>
        }
      </div>
    </div>
  );
};

export default FirstImpact;
