import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import * as dayjs from "dayjs";
import IntlMessages, { IntlGetText } from "util/IntlMessages";
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@material-ui/core";
import FormInput from "components/Form/Controls/input";
import FormCheckbox from "components/Form/Controls/checkbox";
import FormDatePicker from "components/Form/Controls/datepicker";
import ButtonAsync from "components/Form/Controls/ButtonAsync";

const FormCRU =({ rowObj2, impactId, openCRU, handleRequestClose, handleCreateUpdate })=>{
    const msgSuccess = IntlGetText("core.SuccessSave");
  
    const [ rowObj, setCurrentObj] = useState(null);
  
    const defaultValues = {
      activePrinciple: "",
      dosage: "",
      measurementUnit: "",
      administrationRoute: "",
      supplyFrequency: "",
      treatmentStartDate: null,
      treatmentEndDate: null,
      lifeTime: false
    };
  
    const validationSchema = {
      activePrinciple: yup.string().required(<IntlMessages id="core.fieldRequired" />),
    };
  
    const methods = useForm({ 
      defaultValues,
      resolver: yupResolver(yup.object().shape(validationSchema)),
    });
    const { handleSubmit, watch, setValue, reset, errors } = methods;
    
    const lifeTimeValue = watch("lifeTime");

    const chkHandleChange = (name) => (e) => setValue(name, e.target.checked);
  
    const onSubmit = async (d, e) => {
      var obj = {
        id: !rowObj ? 0 : rowObj.id,
        impactId: !rowObj ? impactId : rowObj.impactId,
        activePrinciple: d.activePrinciple,
        dosage: d.dosage != null && d.dosage != ""? Number(d.dosage): null,
        measurementUnit: d.measurementUnit,
        administrationRoute: d.administrationRoute,
        supplyFrequency: d.supplyFrequency,
        treatmentStartDate:  !d.treatmentStartDate ? null : dayjs(d.treatmentStartDate).format("YYYY-MM-DDTHH:mm:ss"),
        treatmentEndDate:  d.lifeTime || !d.treatmentEndDate ? null : dayjs(d.treatmentEndDate).format("YYYY-MM-DDTHH:mm:ss"),
        lifeTime: d.lifeTime
      };
      await handleCreateUpdate(obj);
      handleRequestClose();
    };
  
    useEffect(()=>{    
      setCurrentObj(rowObj2);
    },[rowObj2]);
  
    const loadData = () => {
      setValue("activePrinciple", !rowObj ? "" : rowObj.activePrinciple);
      setValue("dosage", !rowObj ? "" : rowObj.dosage);
      setValue("measurementUnit", !rowObj ? "" : rowObj.measurementUnit);
      setValue("administrationRoute", !rowObj ? "" : rowObj.administrationRoute);
      setValue("supplyFrequency", !rowObj ? "" : rowObj.supplyFrequency);
      setValue("treatmentStartDate", !rowObj ? null : rowObj.treatmentStartDate);
      setValue("treatmentEndDate", !rowObj ? null : rowObj.treatmentEndDate);
      setValue("lifeTime", !rowObj ? false : rowObj.lifeTime);
    };

    useEffect(()=>{
      if(openCRU){
        setTimeout(loadData,150);
      }
    },[rowObj,openCRU]);
  
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openCRU}
        disableBackdropClick={true}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          <IntlMessages id={!rowObj ? "core.add" : "core.edit"} />
        </DialogTitle>
        <DialogContent>
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <FormProvider {...methods}>
              <form className="row" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="activePrinciple"
                      label={<IntlMessages id="core.activePrinciple" />}
                      margin="normal"
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="dosage"
                      label={<IntlMessages id="core.dosage" />}
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="measurementUnit"
                      label={<IntlMessages id="core.measurementUnit" />}
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="administrationRoute"
                      label={<IntlMessages id="formula.administrationRoute" />}
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormInput
                      name="supplyFrequency"
                      label={<IntlMessages id="formula.supplyFrequency" />}
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <FormDatePicker
                      name="treatmentStartDate"
                      label={<IntlMessages id="additionalMedications.treatmentStartDate" />}
                      format="YYYY-MM-DD"
                      disableFuture={true}
                      margin="normal"
                      errorobj={errors}
                    />
                  </Grid>
                  { !lifeTimeValue && 
                    <Grid item xs={12} md={12} lg={6}>
                      <FormDatePicker
                        name="treatmentEndDate"
                        label={<IntlMessages id="additionalMedications.treatmentEndDate" />}
                        format="YYYY-MM-DD"
                        margin="normal"
                        errorobj={errors}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} md={12} lg={12}>
                    <FormCheckbox
                      name="lifeTime"
                      label={<IntlMessages id="additionalMedications.lifeTime" />}
                      value={watch("lifeTime")}
                      onChange={chkHandleChange("lifeTime")}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
            <div className="col-lg-12 col-md-12 col-xs-12 text-center mt-4">
              <ButtonAsync onClick={handleSubmit(onSubmit)}
                variant="contained"
                color="primary"
              >
                {<IntlMessages id="core.save" />}
              </ButtonAsync>
            </div>
          </div>  
        </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="secondary">
              <IntlMessages id="core.close" />
            </Button>
          </DialogActions>
        </Dialog>  
    );
  };

  export default FormCRU;